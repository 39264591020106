import React from "react";
import FirstSection from "./FirstSection";
import Layout from "../layout";

function PrivacyPage(
    {
        context,
        dark,
        privacyContent,
        viewAlone
    }) {
    if(viewAlone){
        return <FirstSection  privacyContent={privacyContent} viewAlone/>;
    }
    return <Layout context={context} title="Privacy Policy" dark={dark?1:0} scrollIndicatorHidden>
        <FirstSection  privacyContent={privacyContent} />
    </Layout>;
}

export default PrivacyPage
