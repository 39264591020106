import React from "react"
import styled from "styled-components"
import { Button } from "../Button"
import { getUrl } from "../../config/server"
import Slider from "../Slider/Slider"
//import icApp from "../../assets/icons/ic_app.svg"
import icDownloads from "../../assets/icons/ic_download.svg"
import icUser from "../../assets/icons/ic_user.svg"
import ReactMarkdown from "react-markdown";

const Number = styled.h1`
    color: #f3f3f3;
    grid-area: number;
    position: relative;
    font-size: 12em;
    z-index: -1;
    justify-self: left;
    line-height: 1.5em;
`

const Portfolio = styled.div`
    display: grid;
    position: relative;
    grid-template:
        "number" 9.7em
        "title"
        "." 15px
        "divider"
        "." 15px
        "header"
        "." 50px
        "slider"
        "." 15px
        "description"
        "." 15px
        "stats"
        "." 30px
        "button"
        "." 40px
        "markets"
        "." 0px
        /100vw;
    align-self: center;
    justify-items: center;
    text-align: center;
    @media (min-width: 800px) {
        overflow: hidden;
        width: 100vw;
        max-width: 100%;
        :nth-of-type(odd) {
            grid-template:
                ". . . . slider" .1fr
                "number . . . slider" 9.7em
                ". title divider . slider"
                ". . . . slider" 1fr
                ". technologies technologies . slider"
                ". . . . slider" 1fr
                ". header header . slider"
                ". . . . slider" 1fr
                ". description description . slider"
                ". . . . slider" 1fr
                ". stats stats . slider"
                ". . . . slider" 1fr
                ". button markets . slider"
                ". . . . slider" 1fr
                /50px 2fr 1fr 50px 50%;
            justify-items: flex-start;
            text-align: left;
            .portfolio-app-divider{
                justify-self: flex-end;
            }
        }
        :nth-of-type(even) {
            grid-template:
                "slider . . . ." .1fr
                "slider . . number number" 9.7em
                "slider . divider title ."
                "slider . . . ." 1fr
                "slider . technologies technologies ."
                "slider . . . ." 1fr
                "slider . header header ."
                "slider . . . ." 1fr
                "slider . description description ."
                "slider . . . ." 1fr
                "slider . stats stats ."
                "slider . . . ." 1fr
                "slider . markets button ."
                "slider . . . ." 1fr
                /50% 50px 1fr 2fr 50px;
            justify-items: flex-end;
            text-align: right;
            ${Number} {
                justify-self: right;
            }
            .portfolio-app-divider{
                justify-self: flex-start;
            }
        }
    }
`



const Title = styled.h2`
    grid-area: title;
    font-size: 2em;
`

const Divider = styled.div`
    grid-area: divider;
    height: 1px;
    width: 90%;
    background-color: black;
    align-self: center;
`

const Header = styled.div`
    grid-area: header;
    font-size: 1em;
    @media (max-width: 800px) {
        padding: 0 40px;
    }
`

const Technologies = styled.p`
    grid-area: technologies;
    color: #818181;
`
const Description = styled.p`
    grid-area: description;
    color: #818181;
    @media (max-width: 800px) {
        padding: 0 40px;
    }
`

//const Image = styled.img`
//    width: 100%;
//    position: absolute;
//    opacity:0;
//     ${props => props.visible && props.visible===true && `opacity:1; position:relative;`}
//    @media (min-width: 800px) {
//        height: 100%;
//        object-fit: cover;
//    }
//`

const Stats = styled.p`
    grid-area: stats;
    display: grid;
    justify-self: center;
        grid-template:
            "downloads-header downloads-image"
            "downloads-content downloads-image"
            ". ." 20px
            "users-header users-image"
            "users-content users-image";
        place-items: center center;
    @media (min-width: 800px) {
        grid-template:
            "downloads-header downloads-image"
            "downloads-content downloads-image"
            ". ." 50px
            "users-header users-image"
            "users-content users-image";
        grid-auto-rows: 1fr;
    }
`

const DownloadsHeader = styled.span`
    grid-area: downloads-header;
    font-weight: bold;
`

const DownloadsContent = styled.span`
    grid-area: downloads-content;
`

const UsersHeader = styled.span`
    grid-area: users-header;
    font-weight: bold;
`

const UsersContent = styled.span`
    grid-area: users-content;
`

const Markets = styled.div`
    grid-area: markets;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    font-weight: 700;
    text-align: start;
`
const DownloadsImage = styled.img`
    grid-area: downloads-image;
    width: 60px;
`
const UsersImage = styled.img`
    grid-area: users-image;
    width: 60px;
`

export const App = ({
    it,
    isEven,
    title,
    header,
    description,
    stats,
    markets,
    gallery,
    contactButton,
    technologies,
                        description_formatted,
    lang
}) => {
    const { downloads, users} = stats || {};
    return(
    <Portfolio key={header} isEven={isEven}>
        <Number>{it > 11 ? it + 1 : "0" + (it + 1)}</Number>
        <Title>{title}</Title>
        <Divider className="portfolio-app-divider" />
        <Technologies><ReactMarkdown
            source={technologies}
        /></Technologies>
        <Header>{header}</Header>
        <Description><ReactMarkdown
            source={description_formatted}
        /></Description>
        <Slider
            isEven={isEven}
            data={gallery}
            //paddingTopTears = "10px"
            //paddingBottomTears = "10px"
            //render={({ url, visible }) => <Image src={getUrl(url)} alt="" visible ={visible}/>}
        />
        <Stats>
            {downloads&&<DownloadsHeader>Downloads</DownloadsHeader>}
            {downloads&&<DownloadsImage src={icDownloads} />}
            {downloads&&<DownloadsContent>{downloads}</DownloadsContent>}
            {users&&<UsersHeader>Users</UsersHeader>}
            {users&&<UsersImage src={icUser}/>}
            {users&&<UsersContent>{users}</UsersContent>}
        </Stats>
        <Button to={lang==="pl"?"/contact":"/en/contact"} background="black" color="white">
            {contactButton}
        </Button>
        <Markets>
            Check app
            {markets.map(({ image: { url }, link }) => (
                <a href={link} key={url} target={"_blank"} rel="noreferrer">
                    <img src={getUrl(url)} alt="" height={50} style={{width: "50px"}} />
                </a>
            ))}
        </Markets>
    </Portfolio>
)}

export default App
