import React, { useState } from "react";
import FirstSection from "./FirstSection";
import Layout from "../layout";
import SecondSection from "./SecondSection";

function PortfolioPage({
    context,
    dark,
    firstSection,
    secondSection
}) {
    const selectedPortfoliosState = useState(0);
    const lang = context.langKey;
    return <Layout context={context} title="Portfolio" dark={dark?1:0}>
        {typeof window !== "undefined"&& !window.location.href.includes(":8000") && !window.location.href.includes("new") && window.gtag('event', 'conversion', {'send_to': 'AW-981106108/57-oCODihPEBELz76dMD'})}
        <FirstSection {...firstSection} selectedPortfoliosState={selectedPortfoliosState} />
        <SecondSection {...secondSection} selectedPortfolios={selectedPortfoliosState[0]} id="second-section" lang={lang}/>
    </Layout>;
}

export default PortfolioPage
