import React from "react"
import styled from "styled-components"
import Section from "../section"
import { Formik } from "formik";
import * as Yup from "yup";
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import ReactMarkdown from'react-markdown'

const Container = styled(Section)`
    display: flex;
    align-items: center;
    justify-content: center;
    @media (min-width: 800px) {
        padding: 0 60px;
        width: 50vw;
    }
`

const HeaderComponent = styled.h1`
text-align: center;
font-size: 2em;
padding: 20px;
`

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
            maxWidth: "500px",
        },
    },
    textField: {
        fontWeight: "600",
    }

}));

const Form = styled.form`
display: flex;
flex-direction: column;
justify-content: space-evenly;
align-items: center;
.MuiFormControl-root {
    width: 100%;
}
.MuiFilledInput-root {
    width: 100%;
    transition: 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
    background-color: #F0F9FF !important;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
}
.MuiFilledInput-root:hover {
    background-color: #E9F2FF !important;
}
.MuiFilledInput-root:focus {
    background-color: #E9F2FF !important;
}
.MuiFilledInput-underline:after {
        border-bottom: 2px solid #36A2C8;
}
.MuiFilledInput-underline:before {
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-bottom: 2px solid rgba(0, 0, 200, 0.42);
    border-bottom: 2px solid #56C2E8 !important;
    pointer-events: none;
}
.MuiInputLabel-root {
    color: grey;
}
.MuiInputLabel-root.Mui-focused {
    color: black;
}
`

const StyledTextField = styled(TextField)`
`
const StyledHiddenTextField = styled(TextField)`
    display: none !important;
`
const StyledButton = styled.button`
    align-self: center;
    background-color: ${props => props.background};
    border: none;
    outline:none;
    cursor: pointer;
    color: ${props => props.color};
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    font-size: 1rem;
    height: 40px;
    box-shadow: 0px 0px 30px -10px rgba(0, 0, 0, 0.75);
    letter-spacing: 1px;
    text-transform: uppercase;
    padding: 0 30px;
    @media (min-width: 800px) {
        padding: 0 60px;
        height: 60px;
        align-self: flex-end;
    }
`
const Description = styled.div`
font-size: 10px;
`

function ContactForm({
                         header,
                         name,
                         name_required,
                         email,
                         email_required,
                         email_not_valid,
                         phone,
                         question,
                         question_required,
                         send_button,
                         privacy_policy_info
}) {
    const classes = useStyles();

    const onSubmitForm = (values) => {
        fetch('https://cms.netigen.eu/contact-form-messages', {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            if (response.status >= 200 && response.status < 300) {
                typeof window !== "undefined" && !window.location.href.includes("new") && window.gtag('event', 'conversion', {'send_to': 'AW-981106108/2GjECMi-hPEBELz76dMD'})
                window.location.reload();
            } else {
                console.log('Something went no yes');
            }
        }).catch(err => err);
    }
    return (
        <Container height="800px">

            <Formik
                initialValues={{ Name: "", Email: "", Phone: "", Question: "", AntySpam: "" }}
                onSubmit={async values => {
                    //await new Promise(resolve => setTimeout(resolve, 500));
                    //await new Promise(resolve => setTimeout(resolve, 500));
                    //alert(JSON.stringify(values, null, 2));
                    onSubmitForm(values);

                }}
                validationSchema={Yup.object().shape({
                    Name: Yup.string().required(name_required),
                    Email: Yup.string().email(email_not_valid).required(email_required),
                    Phone: Yup.string(),
                    Question: Yup.string().required(question_required),
                    AntySpam: Yup.string().max(0)
                })}
            >
                {props => {
                    const {
                        values,
                        touched,
                        errors,
                        //dirty,
                        isSubmitting,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        //handleReset
                    } = props;
                    return (
                        <Form onSubmit={handleSubmit} className={classes.root}>
                            <HeaderComponent>
                                {header}
                            </HeaderComponent>
                            <StyledTextField
                                className={classes.textField}
                                id="Name"
                                label={name}
                                variant="filled"
                                value={values.Name}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={errors.Name && touched.Name}
                                helperText={errors.Name && touched.Name?errors.Name:""}
                            />

                            <StyledTextField
                                id="Email"
                                label={email}
                                variant="filled"
                                value={values.Email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={errors.Email && touched.Email}
                                helperText={errors.Email && touched.Email?errors.Email:""}
                            />
                            <StyledTextField
                                id="Phone"
                                label={phone}
                                variant="filled"
                                value={values.Phone}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={errors.Phone && touched.Phone}
                                helperText={errors.Phone && touched.Phone?errors.Phone:""}
                            />
                            <StyledTextField
                                id="Question"
                                label={question}
                                variant="filled"
                                multiline={true}
                                rows={6}
                                rowsMax={10}
                                value={values.Question}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={errors.Question && touched.Question}
                                helperText={errors.Question && touched.Question?errors.Question:""}
                            />
                            <StyledHiddenTextField
                                id="AntySpam"
                                label="AntySpam"
                                variant="filled"
                                multiline={true}
                                rows={6}
                                rowsMax={10}
                                value={values.AntySpam}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={errors.AntySpam && touched.AntySpam}
                                helperText={errors.AntySpam && touched.AntySpam?errors.AntySpam:""}
                            />
                            <Description>
                                <ReactMarkdown
                                    source={privacy_policy_info}
                                />
                            </Description>
                            <StyledButton
                                type="submit"
                                disabled={isSubmitting}
                                color="#fff"
                                background="#56C2E8"
                            >
                                {send_button}
                            </StyledButton>
                        </Form>
                    );
                }}
            </Formik>
        </Container>
    )
}

export default ContactForm
