import React from "react"
import styled from "styled-components"
import { getUrl } from "../../config/server"

const Element = styled.div`
    grid-area: ${props => props.area};
    display: grid;
    grid-template:
        "number image" 
        "text image"
        / max-content 170px;
    justify-items: center;
    @media (min-width: 800px) {
        grid-template:
        "number image" 
        "text image"
        / max-content 130px;
    }
    @media (min-width: 1200px) {
        grid-template:
        "number image" 
        "text image"
        / max-content 170px;
    }
   
`

const Number = styled.h1`
    grid-area: number;
    font-size: 3em;
    color: #56c2e8;
    align-self: flex-end;
    @media (min-width: 800px) {
        font-size: 3em;
    }
    @media (min-width: 1200px) {
        font-size: 4.4em;
    }
`

const Text = styled.h2`
    grid-area: text;
    font-size: 1.2em;
`

const Image = styled.img`
    grid-area: image;
    width: 100%;
    justify-self: flex-start;
`

function NumberElement({ number, description, url, area }) {
    return (
        <Element area={area}>
            <Number>{number}</Number>
            <Text>{description}</Text>
            <Image src={getUrl(url)} />
        </Element>
    )
}

export default NumberElement
