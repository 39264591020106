import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import ContactPage from "../components/Contact/ContactPage"

function Contact({pageContext}) {
    const {
        strapi: { contactPage, mainPage },
    } = useStaticQuery(graphql`
        {
            strapi {
                contactPage {
                    dark
                    firstSection {
                        header
                        contactData {
                            address
                            zip
                            krs
                            mail
                            name
                            nip
                            phoneNumber
                            regon
                        }
                        background {
                            url
                        }
                        contacts {
                            address
                            zip
                            krs
                            mail
                            name
                            nip
                            phoneNumber
                            regon
                            role
                        }
                    }
                    secondSection {
                        coordinates {
                            latitude
                            longitude
                        }
                        pin {
                            url
                        }
                        contactFormLabels {
                            header
                            name
                            name_required
                            email
                            email_required
                            email_not_valid
                            phone
                            question
                            question_required
                            send_button
                            privacy_policy_info
                        }
                    }
                    thirdSection {
                        follow
                        apps
                        social {
                            link
                            image {
                                url
                            }
                            lightImage {
                                url
                            }
                        }
                        markets {
                            link
                            image {
                                url
                            }
                            lightImage {
                                url
                            }
                        }
                    }
                }
                mainPage {
                    fourthSection {
                        headerAfterHeart
                        headerBeforeHeart
                        heart {
                            url
                        }
                        companies {
                            url
                            width
                            height
                        }
                        collaborations{
                            description
                            signature
                        }
                    }
                }
            }
        }
    `)

    return <ContactPage context={pageContext} {...contactPage} {...mainPage} />
}

export default Contact
