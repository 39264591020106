import React from "react"
import styled, { createGlobalStyle } from "styled-components"
import { Link } from "gatsby"
import { logo } from "../config/images"
import HamburgerMenu from "react-hamburger-menu"

const GlobalStyle = createGlobalStyle`
    * {
        font-family: 'Cabin', sans-serif;
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        letter-spacing: 0.8px;
        text-align: left;
        line-height: 1.5em;
    }
    body {
     overflow-y: ${props => (props.menuOpened ? "hidden" : "visible")};
    }
    `

const MobileContainer = styled.header`
    position: absolute;
    top: 0;
    width: 100%;
    margin: 0 auto;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 200;
    transition: 0.3s;
    color: ${props => (props.dark ? "white" : "black")};
    @media (min-width: 800px) {
        display: none;
    }
`

const Logo = styled.img`
    height: 60px;
    opacity: ${props => (props.visible ? 1 : 0)};
    transition: 0.3s;
`

const DesktopContainer = styled.header`
    position: absolute;
    top: 0;
    width: 100%;
    margin: 0 auto;
    padding: 80px;
    display: none;
    grid-auto-flow: column;
    grid-template-columns: min-content 1fr min-content;
    justify-content: space-between;
    z-index: 200;
    color: ${props => (props.dark ? "white" : "black")};
    @media (min-width: 800px) {
        display: grid;
        padding: 4vw;
        grid-gap: 2vw;
    }
    @media (min-width: 1200px) {
        display: grid;
        padding: 4.5vw;
    }
    @media (min-width: 1400px) {
        display: grid;
        padding: 60px;
    }
`

const Routes = styled.div`
    white-space: nowrap;
    @media (min-width: 800px) {
        display: grid;
        grid-auto-flow: column;
        height: 100%;
        justify-content: flex-end;
        gap: 3vw;
        @media (min-width: 1200px) {
            gap: 7vw
        }
    }
`

const Route = styled(Link)`
    text-decoration: none;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    color: ${props => (props.dark ? "white" : "black")};
    font-weight: bold;
    letter-spacing: 1px;
    opacity: 50%;
`
const RouteLink = styled.a`
align-items: center;
box-sizing: border-box;
color: rgb(255, 255, 255);
display: flex;
font-family: "Cabin", sans-serif;
font-weight: 700;
height: 72px;
justify-content: center;
letter-spacing: 1px;
line-height: 27px;
margin-bottom: 0px;
margin-left: 0px;
margin-right: 0px;
margin-top: 0px;
opacity: 0.5;
padding-bottom: 0px;
padding-left: 0px;
padding-right: 0px;
padding-top: 0px;
text-align: left;
text-decoration-color: rgb(255, 255, 255);
text-decoration-line: none;
text-decoration-style: solid;
text-decoration-thickness: auto;
text-transform: uppercase;
white-space: nowrap;
-moz-box-align: center;
-moz-box-pack: center
    text-decoration: none;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    color: ${props => (props.dark ? "white" : "black")};
    font-weight: bold;
    letter-spacing: 1px;
    opacity: 50%;
`
const Languages = styled.div`
    display: grid;
    grid-auto-flow: column;
    color: ${props => (props.dark ? "white" : "black")};
`
const LanguageLink = styled(Route)`
    height: 72px;
    width: 72px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: ${props => (props.selected ? 700 : 400)};
    opacity: ${props => (props.selected ? "100%" : "60%")};
    cursor: pointer;
`

const HamburgerMenuWithStyles = styled(HamburgerMenu)`
    cursor: pointer;
    position: fixed;
`

function Header({
    dark,
    polish,
    english,
    routes,
    menuState: [menuOpened, setMenuOpened],
    withBack = true,
    lang
}) {
    const path = typeof window !== 'undefined' ? window.location.pathname : '';
    return (
        <>
            <GlobalStyle menuOpened={menuOpened}/>
            <MobileContainer visible={!menuOpened} dark={dark?1:0}>
                {
                    //withBack ? (
                    //    <BackButton
                    //        onClick={() => navigate(-1)}
                    //        src={icons.back}
                    //        alt="back"
                    //    />
                    //) : null
                }
                <Link to="/">
                    <Logo visible={!menuOpened} src={dark ? logo.iconLight : logo.icon} />
                </Link>
                <HamburgerMenuWithStyles
                    isOpen={menuOpened}
                    menuClicked={() => setMenuOpened(!menuOpened)}
                    width={30}
                    height={20}
                    strokeWidth={3}
                    rotate={0}
                    color={menuOpened ? "white" : dark?"white":"black"}
                    borderRadius={50}
                    animationDuration={0.3}
                />
            </MobileContainer>
            <DesktopContainer>
                <Link to={lang==="en" ? "/en" : "/"}>
                    <Logo
                        visible={true}
                        src={dark ? logo.fullLight : logo.full}
                    />
                </Link>
                <Routes>
                    {routes.map(({ link, name }) => (name=="Blog")?(
                        <RouteLink dark={dark?1:0} href={link} target="_blank">BLOG</RouteLink>
                    ):(<Route dark={dark?1:0} key={link} to={link}
                              activeStyle={{ opacity: "100%" }}
                              partiallyActive={true}
                    >
                        {name}
                    </Route>))}
                </Routes>
                <Languages dark={dark?1:0}>
                    <LanguageLink
                        to={path.replace("/" + lang , "")}
                        selected={lang==="pl"}
                        dark={dark?1:0}
                    >
                        {polish}
                    </LanguageLink>
                    <LanguageLink
                        to={lang==="en"?`${path}`:`/en/${path}`}
                        selected={lang==="en"}
                        dark={dark?1:0}
                    >
                        {english}
                    </LanguageLink>
                </Languages>
            </DesktopContainer>
        </>
    )
}

export default Header
