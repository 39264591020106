import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import AboutPage from "../components/About/AboutPage"

function About({pageContext}) {
    const {
        strapi: { aboutPageEn, mainPageEn },
    } = useStaticQuery(graphql`
        {
            strapi {
                aboutPageEn {
                    dark
                    firstSection {
                        background {
                            url
                        }
                        question
                        teamOption
                        numbersOption
                        header
                    }
                    sideBackground {
                        url
                    }
                    secondSection {
                        title
                        header
                        description
                        photo {
                            url
                        }
                        description_formatted
                    }
                    thirdSection {
                        header
                        gallery {
                            url
                            width
                            height
                        }
                    }
                }
                mainPageEn {
                    secondSection {
                        aboutButton
                        header
                        elements {
                            image {
                                url
                            }
                            description
                            number
                        }
                    }
                }
            }
        }
    `)

    return <AboutPage context={pageContext} {...aboutPageEn} mainPage={mainPageEn} />
}

export default About
