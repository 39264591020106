import React from 'react'
import Layout from '../layout'
import FirstSection from './FirstSection'
import SecondSection from './SecondSection'
import MainPageSecondSection from './../Main/SecondSection'
import ThirdSection from './ThirdSection'

function AboutPage({
    context,
    dark,
    firstSection,
    secondSection,
    thirdSection,
    mainPage:{secondSection:mainPageSecondSection}
}) {
    return (
        <Layout context={context} dark={dark?1:0} title="About">
            <FirstSection {...firstSection} />
            <SecondSection {...secondSection} />
            <ThirdSection {...thirdSection} />
            <MainPageSecondSection {...mainPageSecondSection} aboutButtonHidden/>
        </Layout>
    )
}

export default AboutPage
