import React from "react"
import { Container } from "../shared/SectionComponents"
import ReactMarkdown from'react-markdown'

import styled, { createGlobalStyle } from "styled-components"

const GlobalStyle = createGlobalStyle`
    #privacy-first-section-container{
        * {
         margin: revert;
        }
    }`

const Content = styled.div`
    height: 100%;
`
const ViewAloneContent = styled.div`
    
`

function FirstSection({
                          viewAlone,
                          privacyContent
}) {
    const {content} = privacyContent||{};
    if(viewAlone){
        return <ViewAloneContent>
            <ReactMarkdown
                source={content}
            />
        </ViewAloneContent>
    } else {
        return (
            <Container height="100%" id="privacy-first-section-container">
                <GlobalStyle/>
                <Content>
                    <ReactMarkdown
                        source={content}
                    />
                </Content>
            </Container>
        )
    }

}

export default FirstSection
