import React  from "react"
import styled from "styled-components"
import Section from "../section"
import { collagePhotos } from "../../config/images"

const Container = styled(Section)`
    display: grid;
    gap: 50px;
    margin-bottom: 30px;
`

const Header = styled.h1`
    justify-self: center;
    font-size: 2em;
    @media (min-width: 800px) {
        font-size: 5em;
        justify-self: flex-end;
        padding-right: 100px;
    }
`

const Collage = styled.div`
    display: grid;
    
    grid-auto-flow: row;
    grid-template-columns: 1fr;
    grid-auto-rows: 300px;
    gap: 20px;
    margin: 0 20px;
    @media (min-width: 800px) {
        //grid-auto-flow: column;
        grid-template-columns: repeat(2, 1fr);
    }
    @media (min-width: 1200px) {
        //grid-auto-flow: column;
        grid-template-columns: repeat(4, 1fr);
    }
`

const Image = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    ${props => props.long && "grid-row: span 2;"}
    ${props => props.rows && `grid-row: span ${props.rows};`}
    ${props => props.columns && `grid-column: span ${props.columns};`}
`

function ThirdSection({ header, gallery }) {
    //const images = useMemo(() => shuffle(gallery).flat(), [gallery])
    return (
        <Container id="about-teams">
            <Header>{header}</Header>
            {/*<Collage>
                {images.map(({ url, aspectRatio }) => (
                    <Image key={url} src={getUrl(url)} long={aspectRatio === 2} a={console.log("aspectRatio: ", aspectRatio)} alt="" />
                ))}
            </Collage>*/}
            <Collage>
                {Object.values(collagePhotos).map(({ src, rows, columns }) => (
                    <Image key={src} src={src} rows={rows} columns={columns} alt="" />
                ))}
            </Collage>
        </Container>
    )
}

export default ThirdSection
