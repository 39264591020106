import React from "react"
import styled from "styled-components"
import Section from "../section"
import { getUrl } from "../../config/server"

const linkHeight = "3em"

const Container = styled(Section)`
    display: grid;
    grid-template:
        ". . . ." 60px
        ". follow . social" ${linkHeight}
        ". . . ." 30px
        ". apps . markets" ${linkHeight}
        ". . . ." 60px
        / 20px max-content 10px 1fr;
    background-color: #56c2e8;
    color: white;
    align-items: center;
    @media (min-width: 800px) {
        grid-template:
            ".   follow .           social      .     apps        .    markets     ."
            / 1fr max-content 50px max-content 100px max-content 50px max-content 1fr;
    }
`

const Follow = styled.p`
    grid-area: follow;
`

const Social = styled.div`
    grid-area: social;
    display: grid;
    grid-auto-flow: column;
    place-items: center center;
    height: ${linkHeight};
    @media (min-width: 800px) {
        grid-gap: 20px;
    }
`

const Apps = styled.p`
    grid-area: apps;
`

const Markets = styled.div`
    grid-area: markets;
    display: grid;
    grid-auto-flow: column;
    place-items: center center;
    height: ${linkHeight};
    @media (min-width: 800px) {
        grid-gap: 20px;
    }
`

const Route = styled.img`
    height: ${linkHeight};
`

function ThirdSection({ follow, social, apps, markets }) {
    return (
        <Container height="300px">
            <Follow>{follow}</Follow>
            <Social>
                {social.map(({ lightImage: { url }, link }) => (
                    <a href={link} style={{ height: linkHeight }} key={url} target="_blank" rel="noreferrer">
                        <Route src={getUrl(url)} />
                    </a>
                ))}
            </Social>
            <Apps>{apps}</Apps>
            <Markets>
                {markets.map(({ lightImage: { url }, link }) => (
                    <a href={link} style={{ height: linkHeight }} key={url} target="_blank" rel="noreferrer">
                        <Route src={getUrl(url)} />
                    </a>
                ))}
            </Markets>
        </Container>
    )
}

export default ThirdSection
