import React from "react"

import Layout from "../components/layout"

const NotFoundPage = ({pageContext}) => (
  <Layout context={pageContext} title="404 Not Found">
    <h1>NOT FOUND</h1>
  </Layout>
)

export default NotFoundPage
