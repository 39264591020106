import React, { useState } from "react"
import PropTypes from "prop-types"

import Header from "./Header"

import darkScroll from "../assets/icons/ic_mouse.svg"
import lightScroll from "../assets/icons/ic_mouse_light.svg"
import goTop from "../assets/icons/ic_gotop.svg"
import ScrollUpButton from "react-scroll-up-button";

import styled, { keyframes } from "styled-components"
import SEO from "./seo"
import { useStaticQuery, graphql, Link } from "gatsby"
import { getUrl } from "../config/server"
import Footer from "./Footer"
import PageTransition from 'gatsby-plugin-page-transitions';

const scrollIndicatorAnimation = keyframes`
  50% {
    transform: translate(-50%, 10px);
  }
`;

const ScrollIndicator = styled.img`
    animation: ${scrollIndicatorAnimation} 2s ease-in-out infinite 0s;
    position: absolute;
    bottom: 80px;
    height: 60px;
    left: 50%;
    transform: translateX(-50%);
    @media (max-width: 800px) {
        display: none;
    }
`

const MenuMobile = styled.div`
    position: fixed;
    height: 100vh;
    width: 100vw;
    transition: 0.3s;
    opacity: ${props => (props.opened ? 1 : 0)};
    z-index: ${props => (props.opened ? 100 : -100)};
    background: #56c2e8;
    display: grid;
    overflow-y: hidden;
    grid-template:
        "." 104px
        "links" 50fr
        "." 5fr
        "follow" 25fr
        "." 3fr;
`

const Routes = styled.div`
    grid-area: links;
    display: grid;
    justify-content: center;
    align-items: center;
    height: 100%;
`

const Route = styled(Link)`
    display: grid;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 2em;
    color: white;
`

const Follow = styled.div`
    grid-area: follow;
    display: grid;
    grid-auto-flow: column;
    place-items: center center;
    font-size: 1em;
    color: white;
`

const Image = styled.img`
    width: 45px;
    height: 45px;
`

function Layout({ context, dark, title, children, withBack, scrollIndicatorHidden }) {
    const menuState = useState(false)

    const lang = context.langKey;
    const langKey = lang === "pl" ? "" : "En"

    const { strapi } = useStaticQuery(graphql`
        query {
            strapi {
                header {
                    header {
                        follow
                        links {
                            link
                            lightImage {
                                url
                            }
                        }
                        english
                        polish
                        routes {
                            name
                            route {
                                link
                                name
                            }
                        }
                    }
                }
                headerEn {
                    header {
                        follow
                        links {
                            link
                            lightImage {
                                url
                            }
                        }
                        english
                        polish
                        routes {
                            name
                            route {
                                link
                                name
                            }
                        }
                    }
                }
            }
        }
    `)
    const {
        header: {
            follow,
            english,
            polish,
            links,
            routes: { route },
        },
    } = strapi[`header${langKey}`]

    return (
        <>
            <MenuMobile opened={menuState[0]}>
                <Routes>
                    {route.map(({ link, name }) => (
                        <Route
                            key={name}
                            to={link}
                            onClick={()=>menuState[1](false)} //for close hamburger menu when choose page even if we're already on that page
                        >
                            {name}
                        </Route>
                    ))}
                </Routes>
                <Follow>
                    {follow}
                    {links.map(({ link, lightImage: { url } }) => (
                        <a href={link}> <img key={url} src={getUrl(url)} height={50} alt="" /></a>
                    ))}
                </Follow>
            </MenuMobile>
            <Header
                context={context}
                dark={dark}
                menuState={menuState}
                siteTitle="Netigen"
                withBack={withBack}
                routes={route}
                polish={polish}
                english={english}
                lang={lang}
            />
            <SEO
                title={title}
                lang={lang}
            />
            <main
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyItems: "center",
                    overflowX: "hidden"
                }}
            >
                <PageTransition
                    transitionStyles={{
                        entering: { opacity: '100%' },
                        entered: { opacity: '100%' },
                        exiting: { opacity: '100%' },

                    }}
                    transitionTime={150}
                >
                {children}
                </PageTransition>
            </main>
            {!scrollIndicatorHidden && <ScrollUpButton
                StopPosition={0}
                ShowAtPosition={10}
                style={{
                    position: "fixed",
                    right: "-60px",
                    bottom: "-60px",
                    transition: "bottom 1s",
                    height: "60px",
                    left: "50%",
                    transform: "translateX(-50%)",
                }}
                ToggledStyle={{bottom: "-180px"}}
            ><ScrollIndicator src={dark ? lightScroll : darkScroll} /></ScrollUpButton>}
            <Footer
                lang={lang}
            />
            <ScrollUpButton
                style={{
                    position: "fixed",
                    right: "-60px",
                    bottom: "50px",
                    transition: "right 0.5s",
                    cursor: "pointer",
                }}
                ToggledStyle={{right: "20px"}}
            >
                <Image src={goTop} />
            </ScrollUpButton>
        </>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
