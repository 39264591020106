import React from "react"
import styled from "styled-components"
import { Container as SectionContainer } from "./shared/SectionComponents"
import { Link, useStaticQuery, graphql } from "gatsby"
import { getUrl } from "../config/server";
import CookieConsent from "react-cookie-consent";
import ReactMarkdown from'react-markdown'
import Obfuscate from "react-obfuscate";

export const FooterContainer = styled(SectionContainer)`
    min-height: 1px;
    position: relative;
    background: #111;
    padding-top: 50px;
    padding-bottom: 10px;
    @media (min-width: 800px) {
        padding-top: 60px;
        padding-bottom: 20px;
        justify-content: center;
    }
    @media (min-width: 1200px) {
        padding-top: 80px;
        padding-bottom: 30px;
        justify-content: center;
    }
    @media (max-width: 1400px) {
        padding-left: 4vw;
        padding-right: 4vw;
    }
`;

const Container = styled.div`
    grid-area: container;
    width: 100%;
    grid-template:
        "logoImage"
        "." 45px
        "contact"
        "." 60px
        "numbers" 
        "." 40px
        "horizontalDivider1" 1px
        "." 40px
         "marketAndSocials"
        "." 40px
        "horizontalDivider2" 1px
        "." 40px
         "footerBottom"
        / max-content;
    display: grid;
    justify-content: center;
    text-align: center;
    color: white;
    font-size: 1em;
    line-height: 1.5em;
    @media (min-width: 800px) {
        grid-template:
        ". logoImage logoImage contact . numbers verticalDivider1 links verticalDivider2 marketAndSocials marketAndSocials ." auto 
        ". logoImage logoImage contact . numbers verticalDivider1 links verticalDivider2 marketAndSocials marketAndSocials ." auto 
        ". . . . . . . . . . . ." 30px 
        ". horizontalDivider2 horizontalDivider2 horizontalDivider2 horizontalDivider2 horizontalDivider2 horizontalDivider2 horizontalDivider2 horizontalDivider2 horizontalDivider2 horizontalDivider2 ." 1px 
        ". . . . . . . . . . . ." 30px 
        ". footerBottom footerBottom footerBottom footerBottom footerBottom footerBottom footerBottom footerBottom footerBottom footerBottom ." auto
        / 0 max-content max-content max-content 1fr max-content 1fr max-content 1fr auto auto 0;
    }
    @media (min-width: 1200px) {
        grid-template:
        ". logoImage . contact . numbers verticalDivider1 links verticalDivider2 marketAndSocials marketAndSocials ." auto 
        ". logoImage . contact . numbers verticalDivider1 links verticalDivider2 marketAndSocials marketAndSocials ." auto 
        ". . . . . . . . . . . ." 30px 
        ". horizontalDivider2 horizontalDivider2 horizontalDivider2 horizontalDivider2 horizontalDivider2 horizontalDivider2 horizontalDivider2 horizontalDivider2 horizontalDivider2 horizontalDivider2 ." 1px 
        ". . . . . . . . . . . ." 30px 
        ". footerBottom footerBottom footerBottom footerBottom footerBottom footerBottom footerBottom footerBottom footerBottom footerBottom ." auto
        / 0 max-content 1fr max-content 1fr max-content 1fr max-content 1fr auto auto 0;
    }
`

const LogoImage = styled.div`
    grid-area: logoImage;
    width: 50px;
    height: 100%;
    justify-self: center;
    align-self: center;
    margin: 0 1vw;
    ${props => props.logoUrlMobile && `background: url(${getUrl(props.logoUrlMobile)});`}
    @media (min-width: 1200px) {
        width: 200px;
        margin: 0 5px;
        ${props => props.logoUrl && `background: url(${getUrl(props.logoUrl)});`}
        background-repeat:no-repeat;
        background-position: center center;
    }
    background-repeat:no-repeat;
    background-position: center center;
`
const Contact = styled.div`
    grid-area: contact;
    text-align: center;
    @media (min-width: 800px) {
        text-align: left;
    }
`
const PhoneNumberLink = styled(Obfuscate)`
    text-decoration: none;
    color: inherit;
`
const MailAddressLink = styled(Obfuscate)`
    text-decoration: none;
    color: inherit;
`
const Numbers = styled.div`
    grid-area: numbers;
    text-align: center;
    @media (min-width: 800px) {
        text-align: left;
    }
`
const Links = styled.div`
    grid-area: links;
    display: none;
    @media (min-width: 800px) {
        display: flex;
        flex-direction: column;
        justify-items: space-between;
        text-align: left;
    }
`
const Route = styled(Link)`
    display: block;
    text-decoration: none;
    color: white;
`
const MarketAndSocials = styled.div`
    grid-area: marketAndSocials;
    //align-self: center;
    display: grid;
    grid-template:
        "socialsText . socialItems" auto 
        ". . ." 40px 
        "marketsText . marketItems" auto 
        /auto 1vw auto;
`
const SocialsText = styled.div`
    grid-area: socialsText;
    text-align: left;
    align-self: center;
`
const SocialItems = styled.div`
    grid-area: socialItems;
    align-self: center;
`
const MarketItems = styled.div`
    grid-area: marketItems;
    align-self: center;
`
const MarketsText = styled.div`
    grid-area: marketsText;
    text-align: left;
    align-self: center;
`
const VerticalDivider1 = styled.div`
    position: relative;
    grid-area: verticalDivider1;
`
const VerticalDivider2 = styled.div`
    position: relative;
    grid-area: verticalDivider2;
`
const VerticalLine = styled.div`
    display: none;
    @media (min-width: 800px) {
        display: flex;
        border-left: 1px solid grey;
        height: 70%;
        position: absolute;
        left: 50%;
        margin-left: -1px;
        top: 0;
    }
`
const HorizontalDivider1 = styled.div`
    position: relative;
    grid-area: horizontalDivider1;
`
const HorizontalDivider2 = styled.div`
    position: relative;
    grid-area: horizontalDivider2;
`
const HorizontalLine = styled.div`
    grid-area: horizontalLine;
    display: flex;
    background-color: grey;
    height:100%;
`
const IconLink = styled.a`
    margin: 10px;
`
const FooterBottom = styled.div`
    display: flex;
    grid-area: footerBottom;
    justify-content: space-between;
    @media (min-width: 800px) {
        display: flex;
    }
`
const BottomLink = styled(Link)`
    //display: flex;
    text-decoration: none;
    color: grey;
`
function Footer({lang}) {

    const langKey = lang === "pl" ? "" : "En";

    const { strapi } = useStaticQuery(graphql`
        query MyQuery {
            strapi {
                footerEn {
                    footer {
                        logo {
                            url
                        }
                        logoMobile {
                            url
                        }
                        contactData {
                            address
                            krs
                            mail
                            name
                            nip
                            phoneNumber
                            regon
                            zip
                        }
                        route {
                            route {
                                link
                                name
                            }
                        }
                        follow
                        socials {
                            lightImage {
                                url
                            }
                            link
                        }
                        apps
                        markets {
                            lightImage {
                                url
                            }
                            link
                        }
                        privacyLink {
                            name
                            link
                        }
                        cookiesConsentContent
                    }
                }
                footer {
                    footer {
                        logo {
                            url
                        }
                        logoMobile {
                            url
                        }
                        contactData {
                            address
                            krs
                            mail
                            name
                            nip
                            phoneNumber
                            regon
                            zip
                        }
                        route {
                            route {
                                link
                                name
                            }
                        }
                        follow
                        socials {
                            lightImage {
                                url
                            }
                            link
                        }
                        apps
                        markets {
                            lightImage {
                                url
                            }
                            link
                        }
                        privacyLink {
                            name
                            link
                        }
                        cookiesConsentContent
                    }
                }
            }
        }
    `)

    const {
        footer: {
            logo:{ url: logoUrl },
            logoMobile:{ url: logoUrlMobile },
            contactData: {
                address,
                krs,
                mail,
                name,
                nip,
                zip,
                phoneNumber,
                regon,
            },
            route: { route },
            follow,
            socials,
            apps,
            markets,
            privacyLink: {
                //link: privacyLink,
                name: privacyName
            },
            cookiesConsentContent
        },
    } = strapi[`footer${langKey}`]

    return (
        <FooterContainer>
        <Container>
            <LogoImage logoUrl={logoUrl} logoUrlMobile={logoUrlMobile} alt=""/>
            <Contact>
                <p>{name}</p>
                <p>{address}</p>
                <p>{zip}</p>
                <br />
                <br />
                <p><PhoneNumberLink tel={phoneNumber}/></p>
                <p><MailAddressLink email={mail}/></p>
            </Contact>
            <Numbers>
                <p>NIP: {nip}</p>
                <p>REGON: {regon}</p>
                <p>KRS: {krs}</p>
            </Numbers>
            <VerticalDivider1>
                <VerticalLine/>
            </VerticalDivider1>
            <Links>
                {route.map(({ link, name }) => (
                    <Route key={name} to={link}>
                        {name}
                    </Route>
                ))}
            </Links>
            <VerticalDivider2>
                <VerticalLine/>
            </VerticalDivider2>

            <HorizontalDivider1>
                <HorizontalLine/>
            </HorizontalDivider1>
            <MarketAndSocials>
                <SocialsText>
                    {follow}
                </SocialsText>
                <SocialItems>
                    {socials.map(({ lightImage: { url }, link }) => (
                        <IconLink href={link} key={url} target={"_blank"} rel="noreferrer">
                            <img src={getUrl(url)} alt="" height={40} />
                        </IconLink>
                    ))}
                </SocialItems>

                <MarketsText>
                    {apps}
                </MarketsText>
                <MarketItems>
                    {markets.map(({ lightImage: { url }, link }) => (
                        <IconLink href={link} key={url} target={"_blank"} rel="noreferrer">
                            <img src={getUrl(url)} alt="" height={40} />
                        </IconLink>
                    ))}
                </MarketItems>
            </MarketAndSocials>
            <HorizontalDivider2>
                <HorizontalLine/>
            </HorizontalDivider2>
            <FooterBottom>
                <BottomLink
                    to={lang==="pl"?"/privacy":"/en/privacy"}
                    //href={privacyLink}
                    key={privacyName}
                    target={"_blank"}
                    rel="noreferrer"
                >
                    {privacyName}
                </BottomLink>
                <BottomLink to={lang==="pl"?"/":"/en"}>@2020 Netigen Kluzowicz</BottomLink>
            </FooterBottom>
        </Container>
            <CookieConsent
                //debug={true}
                buttonText="X"
                style={{background: "white", width: "400px", left: "50%", marginLeft: "-200px", marginBottom: "30px", alignItems: "center", boxShadow: "rgb(217, 217, 217) 0px 0px 10px 1px"}}
                buttonStyle={{ fontWeight: "bold", padding: "7px 7px", background: "white" }}
                contentStyle={{ color: "black" }}
            ><ReactMarkdown
                source={cookiesConsentContent}
            /></CookieConsent>
        </FooterContainer>
    )
}

export default Footer
