const { hot } = require("react-hot-loader/root")

// prefer default export if available
const preferDefault = m => m && m.default || m


exports.components = {
  "component---cache-dev-404-page-js": hot(preferDefault(require("/home/admin/domains/netigen.eu/public_html/new/gatsby/.cache/dev-404-page.js"))),
  "component---src-pages-404-js": hot(preferDefault(require("/home/admin/domains/netigen.eu/public_html/new/gatsby/src/pages/404.js"))),
  "component---src-pages-about-en-js": hot(preferDefault(require("/home/admin/domains/netigen.eu/public_html/new/gatsby/src/pages/about.en.js"))),
  "component---src-pages-about-js": hot(preferDefault(require("/home/admin/domains/netigen.eu/public_html/new/gatsby/src/pages/about.js"))),
  "component---src-pages-contact-en-js": hot(preferDefault(require("/home/admin/domains/netigen.eu/public_html/new/gatsby/src/pages/contact.en.js"))),
  "component---src-pages-contact-js": hot(preferDefault(require("/home/admin/domains/netigen.eu/public_html/new/gatsby/src/pages/contact.js"))),
  "component---src-pages-index-en-js": hot(preferDefault(require("/home/admin/domains/netigen.eu/public_html/new/gatsby/src/pages/index.en.js"))),
  "component---src-pages-index-js": hot(preferDefault(require("/home/admin/domains/netigen.eu/public_html/new/gatsby/src/pages/index.js"))),
  "component---src-pages-offer-en-js": hot(preferDefault(require("/home/admin/domains/netigen.eu/public_html/new/gatsby/src/pages/offer.en.js"))),
  "component---src-pages-offer-js": hot(preferDefault(require("/home/admin/domains/netigen.eu/public_html/new/gatsby/src/pages/offer.js"))),
  "component---src-pages-portfolio-en-js": hot(preferDefault(require("/home/admin/domains/netigen.eu/public_html/new/gatsby/src/pages/portfolio.en.js"))),
  "component---src-pages-portfolio-js": hot(preferDefault(require("/home/admin/domains/netigen.eu/public_html/new/gatsby/src/pages/portfolio.js"))),
  "component---src-pages-privacy-compass-competition-regulations-en-js": hot(preferDefault(require("/home/admin/domains/netigen.eu/public_html/new/gatsby/src/pages/privacy/compass-competition-regulations.en.js"))),
  "component---src-pages-privacy-compass-competition-regulations-js": hot(preferDefault(require("/home/admin/domains/netigen.eu/public_html/new/gatsby/src/pages/privacy/compass-competition-regulations.js"))),
  "component---src-pages-privacy-en-js": hot(preferDefault(require("/home/admin/domains/netigen.eu/public_html/new/gatsby/src/pages/privacy.en.js"))),
  "component---src-pages-privacy-js": hot(preferDefault(require("/home/admin/domains/netigen.eu/public_html/new/gatsby/src/pages/privacy.js"))),
  "component---src-pages-privacy-view-alone-en-js": hot(preferDefault(require("/home/admin/domains/netigen.eu/public_html/new/gatsby/src/pages/privacy/view-alone.en.js"))),
  "component---src-pages-privacy-view-alone-js": hot(preferDefault(require("/home/admin/domains/netigen.eu/public_html/new/gatsby/src/pages/privacy/view-alone.js")))
}

