import React from "react"
import styled from "styled-components"
import Section from "../section"
import netigenSrc from "../../assets/images/img_logo_text.svg"
import NumberElement from "./NumberElement"
import { Button } from "../Button"

const Container = styled(Section)`
    display: grid;
    grid-template:
        "." 80px
        "header" max-content
        "." 80px
        "element-0" max-content
        "." 40px
        "element-1" max-content
        "." 40px
        "element-2" max-content
        "." 80px
        "button" 40px
        "." 40px;
    grid-auto-columns: 1fr;
    justify-content: center;
    justify-items: center;
    @media (min-width: 800px) {
        grid-template:
            ". . ." 130px
            "header header header" max-content
            ". . ." 130px
            "element-0 element-1 element-2" 170px
            ". . ." 110px
            ". . button" 60px
            ". . ." 110px / 1fr 1fr 1fr;
    }
`
const Netigen = styled.img`
    height: 1.4em;
    @media (min-width: 800px) {
        height: 1em;
        margin-top: 23px;
        margin-right: 20px;
    }
`
const Header = styled.h1`
    grid-area: header;
    display: flex;
    flex-direction: column;
    font-size: 3em;
    justify-content: center;
    align-items: center;
    @media (min-width: 800px) {
        flex-direction: row;
        height: 5rem;
        font-size: 5rem;
    }
`

function SecondSection({ header, elements, aboutButton,  aboutButtonHidden, lang}) {
    return (
        <Container height="800px">
            <Header>
                <Netigen src={netigenSrc} /> {header}
            </Header>

            {elements.map(({ number, description, image: { url } }, it) => (
                <NumberElement
                    key={description}
                    area={`element-${it}`}
                    number={number}
                    description={description}
                    url={url}
                />
            ))}

            {!aboutButtonHidden&&<Button color="#fff" background="#56C2E8" to={lang==="pl"?"/about":"/en/about"}>
                {aboutButton}
            </Button>}
        </Container>
    )
}

export default SecondSection
