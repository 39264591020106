import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import PrivacyPage from "../components/Privacy/PrivacyPage";

function Privacy({pageContext}) {
    const {
        strapi: { privacyPage },
    } = useStaticQuery(graphql`
        query privacy {
            strapi {
                privacyPage {
                    privacyContent {
                        content
                    }
                }
            }
        }
    `)

    return <PrivacyPage context={pageContext} {...privacyPage} />
}

export default Privacy
