import React from "react"

import MainPage from "../components/Main/MainPage"
import { useStaticQuery, graphql } from "gatsby"

function Index({pageContext}) {
    const {
        strapi: { mainPageEn },
    } = useStaticQuery(graphql`
        {
            strapi {
                mainPageEn {
                    firstSection {
                        description
                        offerButton
                        subtitle
                        title
                        background {
                            url
                        }
                    }
                    dark
                    secondSection {
                        aboutButton
                        header
                        elements {
                            image {
                                url
                            }
                            description
                            number
                        }
                    }
                    thirdSection {
                        background {
                            url
                        }
                        header
                        slider {
                            description
                            title
                            image {
                                url
                            }
                        }
                        portfolioButton
                    }
                    fourthSection {
                        headerAfterHeart
                        headerBeforeHeart
                        heart {
                            url
                        }
                        companies {
                            url
                            width
                            height
                        }
                        collaborations{
                            description
                            signature
                        }
                    }
                }
            }
        }
    `)

    return <MainPage context={pageContext} {...mainPageEn}/>
}
export default Index
