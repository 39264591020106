import React from "react"
import PortfolioPage from "../components/Portfolio/PortfolioPage"
import { useStaticQuery, graphql } from "gatsby"
import { preventWidowsAndOrphans } from "../utils/text";

function Portfolio({pageContext}) {
    const {
        strapi: { portfolioPage },
    } = useStaticQuery(graphql`
        query portfolio {
            strapi {
                portfolioPage {
                    firstSection {
                        background {
                            url
                        }
                        clientsOption
                        netigenOption
                        header
                        question
                    }
                    dark
                    secondSection {
                        contactButton
                        netigenPortfolios(sort: "sort_order") {
                            description
                            header
                            title
                            gallery {
                                url
                            }
                            stats {
                                downloads
                                users
                            }
                            markets {
                                image {
                                    url
                                }
                                link
                            }
                            technologies
                            description_formatted
                        }
                        clientsPortfolios(sort: "sort_order") {
                            description
                            header
                            title
                            gallery {
                                url
                            }
                            stats {
                                downloads
                                users
                            }
                            markets {
                                image {
                                    url
                                }
                                link
                            }
                            technologies
                            description_formatted
                        }
                    }
                }
            }
        }
    `)

    portfolioPage.secondSection.netigenPortfolios = portfolioPage.secondSection.netigenPortfolios.map((element)=>{
        element.description = preventWidowsAndOrphans(element.description);
        return element;
    });
    portfolioPage.secondSection.clientsPortfolios = portfolioPage.secondSection.clientsPortfolios.map((element)=>{
        element.description = preventWidowsAndOrphans(element.description);
        return element;
    });

    return <PortfolioPage context={pageContext} {...portfolioPage} />
}

export default Portfolio
