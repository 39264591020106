import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import PrivacyPage from "../../components/Privacy/PrivacyPage";
import { createGlobalStyle } from "styled-components";
import { preventWidowsAndOrphans } from "../../utils/text";
const GlobalStyle = createGlobalStyle`
    #privacy-first-section-container{
        * {
         margin: revert;
        }
    }`

function PrivacyCompassCompetitionRegulations({pageContext}) {

    const {
        strapi: { privacyPage },
    } = useStaticQuery(graphql`
        query privacyPrivacyCompassCompetitionRegulations {
            strapi {
                privacyPage {
                    privacyContent {
                        content
                    }
                }
            }
        }
    `)

    //portfolioPage.secondSection.netigenPortfolios = portfolioPage.secondSection.netigenPortfolios.map((element)=>{
    //    element.description = preventWidowsAndOrphans(element.description);
    //    return element;
    //});
    privacyPage.privacyContent.content = '**Regulations of the competition \"Kompas Competition\" Netigen\n' +
        'Kluzowicz sp. j.**\n' +
        '\n' +
        '**General provisions**\n' +
        '\n' +
        '-   The competition is organized by Netigen Kluzowicz sp.j. with\n' +
        '    headquarters in Krakow at ul. Dobrego pastrza 19A / U3 NIP:\n' +
        '    9452179580\n' +
        '\n' +
        '-   The organizer is the founder of the award.\n' +
        '\n' +
        '-   The contest is not created, administered, or sponsored by Facebook.\n' +
        '    The facebook.com website does not bear any responsibility for any\n' +
        '    activities related to the organization of the competition on the\n' +
        '    website.\n' +
        '\n' +
        '**Conditions of participation**\n' +
        '\n' +
        '-   Only adults may take part in the competition.\n' +
        '\n' +
        '-   The condition for participation in the competition is to have a\n' +
        '    verified account on Facebook.\n' +
        '\n' +
        '-   The condition for participation in the competition is to like the\n' +
        '    fan page: <https://www.facebook.com/netigenpl>\n' +
        '\n' +
        '-   The competition lasts for 48 hours from the publication of the\n' +
        '    competition post.\n' +
        '\n' +
        '-   The results of the Competition will be announced: via a fan page.\n' +
        '\n' +
        '-   The competition takes place via the fanpage on the social networking\n' +
        '    site Facebook at: <https://www.facebook.com/netigenpl>\n' +
        '\n' +
        '**Competition task**\n' +
        '\n' +
        '-   The contest task is to: like the contest post, make the contest post\n' +
        '    public, and write in response to the contest post what you use the\n' +
        '    compass for.\n' +
        '\n' +
        '-   Each participant may submit only one response proposal.\n' +
        '\n' +
        '-   Three winners will be selected for the competition.\n' +
        '\n' +
        '-   The most interesting, best, and funniest entries will be selected by\n' +
        '    the Netigen team and put to a vote. The three best entries will be\n' +
        '    selected by vote.\n' +
        '\n' +
        '-   The Competition Winners will be notified of the winning and the\n' +
        '    terms of receiving the Prize via a private message sent on Facebook.\n' +
        '\n' +
        '-   The organizer pays attention to respecting the creativity of others\n' +
        '    and preserving culture in statements.\n' +
        '\n' +
        '-   Entries containing profanity and obscene words will be removed and I\n' +
        '    will not take part in the competition.\n' +
        '\n' +
        '**Award**\n' +
        '\n' +
        '-   The award in the competition is full access to the Perfect Compass\n' +
        '    application without advertising for 1 year from the moment of using\n' +
        '    the one-time code.\n' +
        '\n' +
        '-   The award in the form of a one-time code will be sent to the e-mail\n' +
        '    provided by the user.\n' +
        '\n' +
        '-   The prize should be collected no later than 7 days from the\n' +
        '    announcement of the results\n' +
        '\n' +
        '-   The organizer has the right to provide the Winner\\\'s details on the\n' +
        '    fan page.\n' +
        '\n' +
        '-   The Organizer is not responsible for the inability to transfer the\n' +
        '    prize for reasons attributable to the Participant. In this case, the\n' +
        '    award is forfeited.\n' +
        '\n' +
        '-   The organizer is not responsible for incorrect data indicated by\n' +
        '    Participant, and in particular for changing personal data that make\n' +
        '    it impossible to find the Participant and inform them about the\n' +
        '    award of the Prize.\n' +
        '\n' +
        '-   A given participant may be excluded from the Competition by an\n' +
        '    unauthorized selection of the Winner, especially by setting up\n' +
        '    fictitious Facebook profiles\n' +
        '\n' +
        '**Complaints**\n' +
        '\n' +
        '-   Please send any complaints and comments regarding the rules,\n' +
        '    conduct, or settlement of the competition by e-mail to the following\n' +
        '    address:\n' +
        '    [k.sobieraj\@netigen.eu](mailto:k.sobieraj@netigen.eu)\n' +
        '\n' +
        '-   The complaint should include the name and surname of the Participant\n' +
        '    and the justification for the complaint. In the title of the\n' +
        '    message, please add a description (Facebook competition -\n' +
        '    competition title).\n' +
        '\n' +
        '-   Complaints are considered within 30 days from the date of their\n' +
        '    receipt.\n' +
        '\n' +
        '**Information obligation**\n' +
        '\n' +
        '-   Please be advised that the administrator of the personal data of the\n' +
        '    Competition participants is Netigen Kluzowicz sp. j. In matters\n' +
        '    regarding the processing of personal data, please contact the\n' +
        '    following e-mail address:\n' +
        '    [biuro\@netigen.eu](mailto:biuro@netigen.eu)\n' +
        '\n' +
        '-   Personal data will be processed for the purpose and scope necessary\n' +
        '    to conduct the Competition, under art. 6 sec. 1. lit. b of the\n' +
        '    Regulation of the European Parliament and of the Council (EU)\n' +
        '    2016/679 EU (hereinafter: \"GDPR\"). The data was collected by the\n' +
        '    fan page on Facebook Netigen.\n' +
        '\n' +
        '-   Personal data will be processed for the period necessary for the\n' +
        '    implementation of the Competition, including the release of the\n' +
        '    Prize and announcement of the winner (and other awarded persons), as\n' +
        '    well as stored until any claims are time-barred or until the data\n' +
        '    archiving obligation resulting from legal provisions expires.\n' +
        '\n' +
        '**General Provisions**\n' +
        '\n' +
        '-   In matters not covered by these Regulations, the provisions of the\n' +
        '    Civil Code and other legal provisions shall apply.\n' +
        '\n' +
        '-   Disputes related to and arising from the Competition will be\n' +
        '    resolved by a common court competent for the seat of the organizer.\n' +
        '\n' +
        '-   The organizer reserves the right to change the rules of the\n' +
        '    Competition during its duration. Information about changes will be\n' +
        '    posted on the fan page (name of the fan page).\n' +
        '\n' +
        '-   By participating in the competition, the User agrees to the\n' +
        '    provisions of these Regulations.\n'

    return <>
            <GlobalStyle/>
            <PrivacyPage context={pageContext} {...privacyPage} viewAlone/>
        </>
}

export default PrivacyCompassCompetitionRegulations
