import React from "react"
import OfferPage from "../components/Offer/OfferPage"
import { useStaticQuery, graphql } from "gatsby"
import { preventWidowsAndOrphans } from "../utils/text"

function Offer({pageContext}) {
    const {
        strapi: { offerPage, mainPage },
    } = useStaticQuery(graphql`
        query offer {
            strapi {
                mainPage {
                    fourthSection {
                        headerAfterHeart
                        headerBeforeHeart
                        heart {
                            url
                        }
                        companies {
                            url
                            width
                            height
                        }
                        collaborations {
                            description
                            signature
                        }
                    }
                }
                offerPage {
                    dark
                    thirdSection {
                        image {
                            url
                        }
                        header
                        description
                        contactButton
                        title
                        technologiesHeader
                        technologies {
                            url
                        }
                        description_formatted
                    }
                    secondSection {
                        image {
                            url
                        }
                        header
                        description
                        contactButton
                        title
                        technologiesHeader
                        technologies {
                            url
                        }
                        description_formatted
                    }
                    firstSection {
                        background {
                            url
                        }
                        firstOption
                        question
                        secondOption
                        description
                    }
                }
            }
        }
    `)
    offerPage.firstSection.description = preventWidowsAndOrphans(offerPage.firstSection.description);
    offerPage.secondSection.description = preventWidowsAndOrphans(offerPage.secondSection.description);
    offerPage.thirdSection.description = preventWidowsAndOrphans(offerPage.thirdSection.description);
    mainPage.fourthSection.collaborations = mainPage.fourthSection.collaborations.map((element)=>{
        element.description = preventWidowsAndOrphans(element.description);
        return element;
    });

    return <OfferPage context={pageContext} {...offerPage} {...mainPage} />
}

export default Offer
