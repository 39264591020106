import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import PrivacyPage from "../components/Privacy/PrivacyPage";

function Privacy({pageContext}) {
    const {
        strapi: { privacyPageEn },
    } = useStaticQuery(graphql`
        query privacyEn {
            strapi {
                privacyPageEn {
                    privacyContent {
                        content
                    }
                }
            }
        }
    `)

    return <PrivacyPage context={pageContext} {...privacyPageEn} />
}

export default Privacy
