import React from "react"
import { Container, Header, Question } from "../shared/SectionComponents"
import styled from "styled-components"

const Content = styled.div`
    grid-area: content;
    height: 100%;
    position: relative;
    display: grid;
    grid-template:
        "header"
        "." 60px
        "question"
        "." 80px
        "options";
    color: white;
    justify-items: center;
    text-align: center;
    @media (min-width: 800px) {
        position: relative;
        grid-template:
            "header"
            ". ." 90px
            "question"
            ". ." 72px
            "options";
        justify-items: flex-start;
    }
`

const Options = styled.div`
    grid-area: options;
    display: grid;
    grid-template:
        "first"
        "." 20px
        "divider"
        "." 20px
        "second";
    @media (min-width: 800px) {
        grid-template:
            "first . divider . second"
            / max-content 50px 1px 50px max-content;
    }
`

const First = styled.h3`
    grid-area: first;
    cursor: pointer;
    font-size: 2rem;
    @media (min-width: 768px) {
        font-size: 2.5rem;
    }
`

const Divider = styled.div`
    grid-area: divider;
    background: white;
    height: 1px;
    width: 100%;
    @media (min-width: 800px) {
        width: 1px;
        height: 100%;
    }
`

const Second = styled.h3`
    grid-area: second;
    cursor: pointer;
    font-size: 2rem;
    @media (min-width: 768px) {
        font-size: 2.5rem;
    }
`

function FirstSection({
    background: { url },
    description,
    question,
    firstOption,
    secondOption,
}) {
    return (
        <Container height="100vh" background={url}>
            <Content>
                <Header dangerouslySetInnerHTML={{ __html: description }} />

                <Question>{question}</Question>

                <Options>
                    <First
                        onClick={() =>
                            document
                                .getElementById("mobile-apps")
                                .scrollIntoView({ behavior: "smooth" })
                        }
                    >
                        {firstOption}
                    </First>
                    <Divider />
                    <Second
                        onClick={() =>
                            document
                                .getElementById("web-apps")
                                .scrollIntoView({ behavior: "smooth" })
                        }
                    >
                        {secondOption}
                    </Second>
                </Options>
            </Content>
        </Container>
    )
}

export default FirstSection
