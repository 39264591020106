import React from "react"
import styled from "styled-components"
import Section from "../section"
import { getUrl } from "../../config/server"
import { Button } from "../Button"
import ReactMarkdown from "react-markdown";

const Container = styled(Section)`
    display: grid;
    grid-template:
        "." 50px
        "image"
        "." 50px
        "title"
        "." 50px
        "header"
        "." 40px
        "description"
        "." 40px
        "technologies"
        "." 20px
        "showcase"
        "." 80px
        "button"
        "." 80px;
    justify-self: center;
    justify-items: center;
    text-align: center;
    @media (min-width: 800px) {
        position: relative;
        grid-template:
            ". . ." 170px
            ". title image"
            ". . image" 80px
            ". header image"
            ". . image" 50px
            ". description image"
            ". . image" 80px
            ". technologies image"
            ". . image" 50px
            ". showcase image"
            ". . image" 130px
            ". button image"
            ". . ." 170px
            /20px minmax(500px, 670px) minmax(0px, 670px);
        text-align: left;
        justify-items: flex-start;
        place-content: center center;
    }
`
const Image = styled.img`
    grid-area: image;
    max-width: 100%;
    @media (min-width: 800px) {
        position: absolute;
        width: calc(50vw - 150px);
        place-self: center flex-start;
    }
`

const Title = styled.h1`
    grid-area: title;
    font-size: 2.2em;
    @media (min-width: 800px) {
        font-size: 5rem;
    }
`

const Header = styled.h2`
    grid-area: header;
       font-weight: normal;
       padding:0 40px;
       text-align: left;
    @media (min-width: 800px) {
        font-size: 1.5rem;
    }
`

const Description = styled.p`
    grid-area: description;
    opacity: 60%;
    text-align: justify;
    padding:0 40px;
    @media (min-width: 800px) {
        font-size: 1rem;
    }
`

const Technologies = styled.h3`
    grid-area: technologies;

    @media (min-width: 800px) {
        font-size: 1.125rem;
    }
`

const Showcase = styled.div`
    grid-area: showcase;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    @media (min-width: 800px) {
        justify-content: space-between;
        padding-right: 100px;
    }
`

function SecondSection({
    image: { url },
    title,
    header,
    description,
    technologiesHeader,
    technologies,
    contactButton,
                           description_formatted,
                           lang
}) {
    return (
        <Container height="1080px" id="mobile-apps">
            <Image src={getUrl(url)} />
            <Header>{header}</Header>
            <Title>{title}</Title>
            {/*<Description>{description}</Description>*/}
            <Description><ReactMarkdown
                source={description_formatted}
            /></Description>

            <Technologies>{technologiesHeader}</Technologies>
            <Showcase>
                {technologies?.map(({ url }) => (
                    <img
                        key={url}
                        src={getUrl(url)}
                        height={50}
                        alt="Technology"
                    />
                ))}
            </Showcase>
            <Button to={lang==="pl"?"/contact":"/en/contact"} background="black" color="white">
                {contactButton}
            </Button>
        </Container>
    )
}

export default SecondSection
