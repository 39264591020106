import React from 'react'
import PortfolioPage from '../components/Portfolio/PortfolioPage'
import { useStaticQuery, graphql } from 'gatsby';

function Portfolio({pageContext}) {

    const {
        strapi: { portfolioPageEn },
    } = useStaticQuery(graphql`
        query portfolioEn {
            strapi {
                portfolioPageEn {
                    firstSection {
                        background {
                            url
                        }
                        clientsOption
                        netigenOption
                        header
                        question
                    }
                    dark
                    secondSection {
                        contactButton
                        netigenPortfolios(sort: "sort_order") {
                            description
                            header
                            title
                            gallery {
                                url
                            }
                            stats {
                                downloads
                                users
                            }
                            markets {
                                image {
                                    url
                                }
                                link
                            }
                            technologies
                            description_formatted
                        }
                        clientsPortfolios(sort: "sort_order") {
                            description
                            header
                            title
                            gallery {
                                url
                            }
                            stats {
                                downloads
                                users
                            }
                            markets {
                                image {
                                    url
                                }
                                link
                            }
                            technologies
                            description_formatted
                        }
                    }
                }
            }
        }
    `)

    return <PortfolioPage context={pageContext} {...portfolioPageEn} />;

}

export default Portfolio
