import React from "react"
import styled from "styled-components"

const Container = styled.div`
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    @media (min-width: 800px) {
        position: absolute;
        flex-direction: column;
        top: 0;
        ${props => props.paddingTop!=="undefined" ? `padding-top: ${props.paddingTop};` : ``};
        ${props => props.paddingBottom!=="undefined" ? `padding-bottom: ${props.paddingBottom};` : ``};
        ${props => props.position==="right" ? "right: 5vw;" : "left: 5vw;"};
        ${props => props.position==="right" ? "align-items: flex-end;" : "align-items: flex-start;"};
        ${props => props.height !== "undefined" ? `height: ${props.height};` : `height: 100%;`};
    }
    ${props => props.height !== "undefined" ? `height: ${props.height};` : `height: 100%;`};
`

const Tear = styled.div`
    transition: 0.3s;
    width: 30px;
    height: 30px;
    cursor: pointer;
    border-radius: ${props =>
        props.selected ? "80% 0 55% 50% / 55% 0 80% 50%" : "50%"};
    background: ${props => (props.selected ? "#56c2e8" : "#C6C6C6")};
    transform: ${props =>
        props.selected
            ? "rotate(-45deg) scale(1)"
            : "rotate(-45deg) scale(0.5)"};
    margin-top: 20px;
`

function Tears({ data, position, slide, setSlide, paddingTop, paddingBottom, height }) {
    if(data&&data.length<2){
        return null;
    } else {
        return (
            <Container position={position} paddingTop={paddingTop} paddingBottom={paddingBottom} height={height}>
                {data.map((_, it) => (
                    <Tear
                        key={it}
                        onClick={() => setSlide(it)}
                        selected={slide === it}
                    />
                ))}
            </Container>
        )
    }

}

export default Tears
