module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Netigen.eu","short_name":"Netigen","start_url":"/","background_color":"#fff","theme_color":"#56c2e8","display":"minimal-ui","icon":"src/images/logo.png","icons":[{"src":"app-icons/icon-48x48.png","sizes":"48x48","type":"image/png"},{"src":"app-icons/icon-72x72.png","sizes":"72x72","type":"image/png"},{"src":"app-icons/icon-96x96.png","sizes":"96x96","type":"image/png"},{"src":"app-icons/icon-144x144.png","sizes":"144x144","type":"image/png"},{"src":"app-icons/icon-192x192.png","sizes":"192x192","type":"image/png"},{"src":"app-icons/icon-256x256.png","sizes":"256x256","type":"image/png"},{"src":"app-icons/icon-384x384.png","sizes":"384x384","type":"image/png"},{"src":"app-icons/icon-512x512.png","sizes":"512x512","type":"image/png"}],"cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"220fb24917fc8c0ab2045f99e754ab46"},
    },{
      plugin: require('../node_modules/gatsby-plugin-page-transitions/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["AW-981106108"],"pluginConfig":{"head":true,"exclude":["/privacy/**","/en/privacy/**","new.netigen.pl/**"]}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
