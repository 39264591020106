import React, { useEffect, useState } from "react"
import styled from "styled-components"
import Section from "../section"
import { getUrl } from "../../config/server"
import Gallery from "./Gallery"
import Transition from "../Slider/Transition";

const Container = styled(Section)`
`

const Content = styled.div`
    position: relative;
    display: grid;
    grid-template:
        "." 40px
        "header"
        "." 40px
        "slider" 250px
        "." 40px
        "gallery" 200px
        "." 40px
        / 80vw;
    justify-content: center;
    overflow: hidden;
    @media (min-width: 800px) {
        grid-template:
            "." 200px
            "header"
            "." 80px
            "slider" 150px
            "." 80px
            "gallery" 200px
            "." 80px
            / 100vw;
    }
`

const Header = styled.h1`
    grid-area: header;
    font-size: 2.6rem;
    text-align: center;
    @media (min-width: 800px) {
        font-size: 3.75rem;
    }
`

const Before = styled.span`
    ::after {
        content: " ";
    }
`

const Heart = styled.img`
    grid-area: image;
    position: relative;
    height: 2.5rem;
    @media (min-width: 800px) {
        height: 3.75rem;
        top: 20%;
    }
`
const After = styled.span`
    ::before {
        content: " ";
    }
`

const Slider = styled.div`
    position: relative;
    grid-area: slider;
    display: flex;
    text-align: center;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    @media (min-width: 800px) {
    width: 100%;
    }
`
const Description = styled.div`
    grid-area: description;
    font-size: 1rem;
    text-align: center;
    justify-self: center;
    height: 200px;
    @media (min-width: 800px) {
        height: 100px;
        font-size: 1.25rem;
        font-style: italic;
    }
`

const Signature = styled.div`
    grid-area: signature;
    font-size: 1rem;
    display: flex;
    flex-direction: row;
    text-align: center;
    align-items: flex-end;
    font-weight: bold;
    height: 100px;
    //width: 90%;
    @media (min-width: 800px) {
        height: 50px;
        font-size: 1.5rem;
        //width: 50%;
    }
`
const Line = styled.div`
    height: 2px;
    width: 80px;
    background-color: #56c2e8;
    margin-left: 50px;
    @media (min-width: 800px) {
        width: 160px;
    }
    @media (min-width: 1200px) {
        width: 260px;
    }
`;

const Text = styled.div`
    display: flex;
    width: 70vw;
    display: flex;
    align-items: center;
    align-self: center;
    justify-content: center;
    @media (min-width: 800px) {
        width: 60vw;
        justify-items: center;
    }
    @media (min-width: 1200px) {
        width: 50vw;
        justify-items: center;
    }
`;

function FourthSection({
    headerBeforeHeart,
    heart,
    headerAfterHeart,
    companies,
    collaborations
}) {
    const [slide, setSlide] = useState(0)

    useEffect(() => {
        const id = setInterval(() => {
            setSlide(slide => (slide < collaborations.length - 1 ? slide + 1 : 0))
        }, 10000)

        return () => clearInterval(id)
    }, [collaborations.length, slide])
    return (
        <Container height="800px">
            <Content>
                <Header>
                    <Before>{headerBeforeHeart}</Before>
                    <Heart src={getUrl(heart?.url)} alt="heart" />
                    <After>{headerAfterHeart}</After>
                </Header>
                <Slider>
                    <Description>
                        <Transition index={slide}>
                            <Text>
                            {collaborations[slide].description}
                            </Text>
                        </Transition>
                    </Description>
                    <Signature>
                        <Transition index={slide}>
                            <Text>
                            {collaborations[slide].signature}
                                <Line/>
                            </Text>
                        </Transition>
                    </Signature>
                    {/*<Tears
                        data={collaborations}
                        position={"left"}
                        slide={slide}
                        setSlide={setSlide}
                        height={"auto"}
                    />*/}
                </Slider>
                <Gallery companies={companies} />
            </Content>
        </Container>
    )
}

export default FourthSection
