import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import PrivacyPage from "../../components/Privacy/PrivacyPage";
import { createGlobalStyle } from "styled-components";
const GlobalStyle = createGlobalStyle`
    #privacy-first-section-container{
        * {
         margin: revert;
        }
    }`

function PrivacyViewAlone({pageContext}) {

    const {
        strapi: { privacyPage },
    } = useStaticQuery(graphql`
        query privacyViewAlone {
            strapi {
                privacyPage {
                    privacyContent {
                        content
                    }
                }
            }
        }
    `)

    return <>
            <GlobalStyle/>
            <PrivacyPage context={pageContext} {...privacyPage} viewAlone/>
        </>
}

export default PrivacyViewAlone
