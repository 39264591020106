import React from "react"
import styled from "styled-components"
import Section from "../section"
import { getUrl } from "../../config/server"
import { Button } from "../Button"
import ReactMarkdown from "react-markdown";

const Container = styled(Section)`
    display: grid;
    grid-template:
        "." 50px
        "image"
        "." 50px
        "title"
        "." 50px
        "header"
        "." 40px
        "description"
        "." 40px
        "technologies"
        "." 20px
        "showcase"
        "." 80px
        "button"
        "." 80px;
    justify-self: center;
    justify-items: center;
    text-align: center;
    background-color: black;
    color: white;
    @media (min-width: 800px) {
        position: relative;
        grid-template:
            ". . ." 170px
            "image title ."
            "image . ." 80px
            "image header ."
            "image . ." 50px
            "image description ."
            "image . ." 80px
            "image technologies ."
            "image . ." 50px
            "image showcase ."
            "image . ." 130px
            "image button ."
            ". . ." 170px
            / minmax(0px, 670px) 670px 20px;
        text-align: right;
        justify-items: flex-end;
        place-content: center center;
    }
`
const Image = styled.img`
    grid-area: image;
    max-width: 100%;
    @media (min-width: 800px) {
        position: absolute;
        width: calc(50vw - 100px);
        place-self: center flex-end;
    }
`

const Title = styled.h1`
    grid-area: title;
    font-size: 2.2em;
    @media (min-width: 800px) {
        font-size: 5rem;
    }
`

const Header = styled.h2`
    grid-area: header;
    padding: 0 40px;
    text-align: left;
    @media (min-width: 800px) {
        font-size: 1.5rem;
    }
`

const Description = styled.p`
    grid-area: description;
    padding: 0 40px;
    @media (min-width: 800px) {
        font-size: 1rem;
    }
`

const Technologies = styled.h3`
    justify-self: flex-start;
    grid-area: technologies;
    padding: 0 40px;
    @media (min-width: 800px) {
        font-size: 1.125rem;
    }
`

const Showcase = styled.div`
    grid-area: showcase;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    @media (min-width: 800px) {
        justify-content: space-between;
        padding-left: 100px;
    }
`
const Route = styled(Button)`
    background-color: #54C3E9;
    color: white;
`

function ThirdSection({
    image: { url },
    title,
    header,
    description,
    technologiesHeader,
    technologies,
    contactButton,
                           description_formatted,
    lang
}) {
    return (
        <Container height="1080px" id="web-apps">
            <Image src={getUrl(url)} />
            <Header>{header}</Header>
            <Title>{title}</Title>
            <Description><ReactMarkdown
                source={description_formatted}
            /></Description>
            <Technologies>{technologiesHeader}</Technologies>
            <Showcase>
                {technologies?.map(({ url }) => (
                    <img key={url} src={getUrl(url)} height={80} alt="Technology" />
                ))}
            </Showcase>
            <Route to={lang==="pl"?"/contact":"/en/contact"}>{contactButton}</Route>
        </Container>
    )
}

export default ThirdSection
