import React from "react"
import styled from "styled-components"
import { Button } from "../Button"
import { Header, Subheader, Description, Container } from "../shared/SectionComponents"
import mainImage from "../../assets/images/grafika_baner_glowny.png"
import ReactMarkdown from "react-markdown";

const SectionContainer = styled(Container)`
    overflow: hidden;
    padding-top: 100px;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 10px;
    @media (min-width: 800px) {
        padding-top: 200px;
        padding-left: 4vw;
        padding-right: 4vw;
        padding-bottom: 20px;
    }
    @media (min-width: 1200px) {
        padding-top: 200px;
        padding-left: 10vw;
        padding-right: 10vw;
        padding-bottom: 20px;
    }
`

const Content = styled.div`
    grid-area: content;
    height: 100vh;
    display: grid;
    grid-template:
        "." 1fr
        "header" max-content
        "subheader" max-content
        "." 1fr
        "description" max-content
        "." 1fr
        "image" max-content
        "." 1fr
        "button" 40px;
    background-position: initial;
    justify-items: center;
    align-items: center;
    text-align: center;
    padding-bottom: 20px;
    @media (min-width: 800px) {
        position: relative;
        grid-template:
            "header image" max-content
            "subheader image" max-content
            ". image" 42px
            "description image" max-content
            ". image" 42px
            "button image" 60px
            / 60fr 40fr;
       justify-items: flex-start;
    }
    @media (min-width: 1400px) {
        position: relative;
        grid-template:
            "header image" max-content
            "subheader image" max-content
            ". image" 52px
            "description image" max-content
            ". image" 52px
            "button image" 60px
            / 60fr 40fr;
       justify-items: flex-start;
    }
`

const FloatingButton = styled(Button)`
    position: sticky;
    bottom: 10px;
    @media (min-width: 800px) {
        font-size: 1.125rem;
        width: max-content;
        height: 100%;
    }
`
const Image = styled.img`
    //grid-area: image;
    //max-width: 100%;
    position: absolute;
    top: 0;
    right: -80px;
    height: 100vh;
    min-height: 800px;
    visibility: hidden;
    @media (min-width: 1200px) {
        visibility: visible;
    }
`

function FirstSection({
    background: { url },
    title,
    subtitle,
    description,
    offerButton,
    lang
}) {
    return (
        <SectionContainer height="100vh" background={url}>
            <Image src={mainImage} />
            <Content>
                <Header main>{title}</Header>

                <Subheader><ReactMarkdown
                    source={subtitle}
                /></Subheader>

                {/*<Image src={mainImage} />*/}

                <Description main>{description}</Description>

                <FloatingButton to={lang==="pl"?"/offer":"/en/offer"} background="#56C2E8" color="white">
                    {offerButton}
                </FloatingButton>
            </Content>

        </SectionContainer>
    )
}

export default FirstSection
