import React from "react"
import { useEffect } from "react"
import styled from "styled-components"
import { useRef } from "react"

import styles from "./MapStyle.json"
import ContactForm from "./ContactForm"
import { getUrl } from "../../config/server"
import { Line } from "../shared/SectionComponents";

const Div = styled.div`
    display: grid;
    grid-template:
        "lineDivider" 2px
        "contactForm" 800px
        "map" 800px
        / 100vw;
    box-sizing: border-box;
    @media (min-width: 800px) {
        grid-template:
            "map contactForm" 800px
            / 50vw 50vw;
    }
`

const LineDivider = styled(Line)`
    grid-area: lineDivider;
    width: 80vw;
    margin-left: initial;
    justify-self: center;
    @media (min-width: 800px) {
        display: none;
    }
`

const StyledContactForm = styled(ContactForm)`
    grid-area: contactForm;
`

const Map = styled.div`
    grid-area: map;
    height: 800px;
    box-sizing: border-box;
    margin-left: 0 0px;
    @media (min-width: 800px) {
        margin-left: 20px;
    }
`

function SecondSection({
    coordinates: { latitude: lat, longitude: lng },
    pin: { url },
                           contactFormLabels
}) {
    const ref = useRef(null)

    useEffect(() => {
        const script = window.document.createElement("script")
        script.src =
            "https://maps.googleapis.com/maps/api/js?key=AIzaSyCFx5pvEkn3BjDAi2hLPw67cMlGSK8d2gA&callback=initMap"
        script.defer = true
        script.async = true

        window.initMap = () => {
            const position = { lat, lng }

            const map = new window.google.maps.Map(ref.current, {
                center: position,
                zoom: 12,
                styles,
            })

            new window.google.maps.Marker({
                position,
                map,
                icon: {
                    url: getUrl(url),
                },
            })
        }

        window.document.head.appendChild(script)
        return () => window.document.head.removeChild(script)
    }, [lat, lng, url])

    return (
        <Div>
            <LineDivider />
            <StyledContactForm {...contactFormLabels} />
            <Map ref={ref} />
        </Div>
    )
}

export default SecondSection
