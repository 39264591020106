import styled from "styled-components"
import { getUrl } from "../config/server"

const Section = styled.section`
    @media (min-width: 800px) {
        height: ${props => props.height};
    }
    ${props => props.background && `background: url(${getUrl(props.background)});`}
    background-position: right bottom;
    background-size: cover;
    max-width: 100%;
    width: 100vw;
`

export default Section
