import styled from "styled-components"
import Section from '../section';

export const Container = styled(Section)`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    min-height: 800px;
    padding-top: 100px;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 100px;
    @media (min-width: 800px) {
        justify-content: flex-start;
        align-items: flex-start;
        padding-top: 200px;
        padding-left: 4vw;
        padding-right: 4vw;
        padding-bottom: 200px;
    }
    @media (min-width: 1200px) {
        justify-content: flex-start;
        padding-top: 200px;
        padding-left: 10vw;
        padding-right: 10vw;
        padding-bottom: 200px;
    }
`;

export const Header = styled.h1`
    grid-area: header;
    font-size: 2em;
    text-align: left;
    @media (min-width: 800px) {
        font-size: 2.125em;
        text-align: left;
        justify-items: flex-start;
        max-width: 75%;
    }
    @media (min-width: 1200px) {
        font-size: 3em;
        text-align: left;
        justify-items: flex-start;
        max-width: 70%;
    }
    ${props => props.main && `
        grid-area: header;
        color: #56c2e8;
        font-size: 3em;
        letter-spacing: 2px;
        @media (min-width: 800px) {
            font-size: 3.5em;
        }
        @media (min-width: 1200px) {
            font-size: 3.8em;
            line-height: 1.3em;
        }
        @media (min-width: 1400px) {
            font-size: 4em;
        }
    `}
`

export const Subheader = styled.h1`
    grid-area: subheader;
    font-size: 2.2em;
    text-align: left;
    justify-items: flex-start;
    @media (min-width: 800px) {
            text-align: left;
            font-size: 3.5em;
        }
    @media (min-width: 1200px) {
        font-size: 3.8em;
        text-align: left;
         p {
            line-height: 1.3em;
            text-align: left;
         }
    }
    @media (min-width: 1400px) {
        font-size: 4em;
    }
`

export const Description = styled.h1`
    grid-area: description;
    font-size: 2em;
    //font-style: italic;
    font-weight: 400;
    width: 100%;
    @media (min-width: 800px) {
        font-size: 3.125em;
        text-align: justify;
        justify-items: flex-start;
    }

    ${props => props.main && `
        grid-area: description;
        font-size: 0.8em;
        @media (min-width: 800px) {
            font-size: 1rem;
           text-align: left;
            line-height: 1.5em;
            letter-spacing: 1.2px;
        }
        @media (min-width: 1200px) {
            font-size: 1.25em;
        }`
    }
`

export const Question = styled.h2`
    grid-area: question;
    font-size: 1em;
    letter-spacing: 1.2px;
    line-height: 1.5em;
    font-weight: normal;
    @media (min-width: 800px) {
        font-size: 1.5em;
    }
`

export const Line = styled.div`
    height: 2px;
    width: 80px;
    background-color: #56c2e8;
    margin-left: 50px;
    @media (min-width: 800px) {
        width: 160px;
    }
    @media (min-width: 1200px) {
        width: 260px;
    }
`;
