import React from "react"

import App from "./App"

function SecondSection({ netigenPortfolios, clientsPortfolios, contactButton, selectedPortfolios, id, lang }) {
    const portfolios = (selectedPortfolios===1)?clientsPortfolios:netigenPortfolios;
    return <div id={id}>
        {portfolios.map((data, it) => (
            <App
                key={data.header}
                it={it}
                isEven={it % 2 === 0}
                {...data}
                contactButton={contactButton}
                lang={lang}
            />
        ))}
    </div>
}

export default SecondSection
