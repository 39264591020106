import React from "react"

import MainPage from "../components/Main/MainPage"
import { useStaticQuery, graphql } from "gatsby"
import { preventWidowsAndOrphans } from "../utils/text";

export const PageContext = React.createContext()

function Index({ pageContext }) {
    const {
        strapi: { mainPage },
    } = useStaticQuery(graphql`
        {
            strapi {
                mainPage {
                    firstSection {
                        description
                        offerButton
                        subtitle
                        title
                        background {
                            url
                        }
                    }
                    dark
                    secondSection {
                        aboutButton
                        header
                        elements {
                            image {
                                url
                            }
                            description
                            number
                        }
                    }
                    thirdSection {
                        background {
                            url
                        }
                        header
                        slider {
                            description
                            title
                            image {
                                url
                            }
                        }
                        portfolioButton
                    }
                    fourthSection {
                        headerAfterHeart
                        headerBeforeHeart
                        heart {
                            url
                        }
                        companies {
                            url
                            width
                            height
                        }
                        collaborations{
                            description
                            signature
                        }
                    }
                }
            }
        }
    `)
    mainPage.firstSection.description = preventWidowsAndOrphans(mainPage.firstSection.description);
    mainPage.secondSection.elements = mainPage.secondSection.elements.map((element)=>{
        element.description = preventWidowsAndOrphans(element.description);
        return element;
    });
    mainPage.thirdSection.slider = mainPage.thirdSection.slider.map((element)=>{
        element.description = preventWidowsAndOrphans(element.description);
        return element;
    });
    mainPage.fourthSection.collaborations = mainPage.fourthSection.collaborations.map((element)=>{
        element.description = preventWidowsAndOrphans(element.description);
        return element;
    });
    return <MainPage context={pageContext} {...mainPage} />
}
export default Index
