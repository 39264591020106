import React from "react"
import { Container, Header, Question } from "../shared/SectionComponents"

import styled from "styled-components"

const Content = styled.div`
    grid-area: content;
    height: 100%;
    position: relative;
    display: grid;
    grid-template:
        "header"
        "." 60px
        "question"
        "." 80px
        "options";
    justify-items: center;
    text-align: center;
    @media (min-width: 800px) {
        position: relative;
        grid-template:
            "header"
            "." 150px
            "question"
            "." 72px
            "options";
        justify-items: flex-start;
    }
`

const Options = styled.div`
    grid-area: options;
    display: grid;
    grid-template:
        "first"
        "." 20px
        "divider"
        "." 20px
        "second";
    @media (min-width: 800px) {
        grid-template:
            "first . divider . second"
            / max-content 50px 1px 50px max-content;
    }
`

const First = styled.h3`
    grid-area: first;
    font-size: 2rem;
    font-weight: ${props => props.selected?"bold":"normal"};
    color: ${props => props.selected?"#000000":"#aaa"};
    cursor: pointer;
    @media (min-width: 768px) {
        font-size: 2.5rem;
    }
`

const Divider = styled.div`
    grid-area: divider;
    background: black;
    height: 1px;
    width: 100%;
    @media (min-width: 800px) {
        width: 1px;
        height: 100%;
    }
`

const Second = styled.h3`
    grid-area: second;
    font-size: 2rem;
    font-weight: ${props => props.selected?"bold":"normal"};
    color: ${props => props.selected?"#000000":"#aaa"};
    cursor: pointer;
    @media (min-width: 768px) {
        font-size: 2.5rem;
    }
`

function FirstSection({
    background: { url },
    header,
    question,
    netigenOption,
    clientsOption,
                          selectedPortfoliosState: [selectedPortfolios, setSelectedPortfolios]
}) {
    const scrollToSecondSection = () => {
        document.getElementById("second-section").scrollIntoView({ behavior: "smooth" })
    }
    return (
        <Container height="100vh" background={url}>
            <Content>
                <Header dangerouslySetInnerHTML={{ __html: header }} />

                <Question>{question}</Question>

                <Options>
                    <First selected={selectedPortfolios===0} onClick={()=>{
                        setSelectedPortfolios(0);
                        scrollToSecondSection();
                    }}>{netigenOption}</First>
                    <Divider />
                    <Second selected={selectedPortfolios===1} onClick={()=>{
                        setSelectedPortfolios(1);
                        scrollToSecondSection();
                    }}>{clientsOption}</Second>
                </Options>
            </Content>
        </Container>
    )
}

export default FirstSection
