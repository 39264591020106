import React from "react"
import Layout from "../layout"
import FirstSection from "./FirstSection"
import SecondSection from "./SecondSection"
import ThirdSection from "./ThirdSection"

function ContactPage({
    context,
    dark,
    firstSection,
    secondSection,
    thirdSection,
}) {
    return (
        <Layout context={context} dark={dark?1:0} title="Contact">
            <FirstSection {...firstSection} />
            <SecondSection {...secondSection} />
            <ThirdSection {...thirdSection} />
        </Layout>
    )
}

export default ContactPage
