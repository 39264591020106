import React from "react"
import styled from "styled-components"
import { icons } from "../../config/images"
import { Container, Header } from "../shared/SectionComponents"
import Obfuscate from 'react-obfuscate';


const ContactContainer = styled(Container)`
    padding-bottom: 50px;
    
    @media (max-width: 800px) {
        min-height: 150vh;
    }
    @media (min-width: 800px) {
        padding-bottom: 70px;
    }
    @media (min-width: 1200px) {
        padding-bottom: 100px;
    }
`
const Content = styled.div`
    grid-area: content;
    height: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-position-x: 90%;
    text-align: center;
    @media (min-width: 800px) {
    min-height: 40vh;
    }
`
const Contact = styled.div`
    grid-area: contact;
    display: flex;
    flex-direction: column;
    @media (min-width: 800px) {
    }
`
const ContactData = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (min-width: 800px) {
    flex-direction: row;
    }
`
const PersonalContacts = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (min-width: 800px) {
      flex-direction: row;
      align-items: start;
    }
`
const PersonalContact = styled.div`
    display: flex;
    flex-direction: row;
    width: 295px;
    justify-content: center;
    @media (max-width: 799px) {
      margin-top: 5px;
      margin-bottom: 35px;
    }
    @media (min-width: 800px) {
          justify-content: flex-start;
    }
`
const PersonalContactData = styled.div`
    display: flex;
    flex-direction: column;
    @media (min-width: 800px) {
        align-items: flex-start;
    }
`
const ContactHeader = styled(Header)`
    @media (min-width: 1200px) {
        max-width: 100%;
    }
`

const Location = styled.div`
    grid-area: location;
    display: flex;
    flex-direction: column;
    letter-spacing: 1.2px;
    line-height: 1.5em;
    @media (min-width: 800px) {
            justify-items: left;
            flex-direction: row;
    }
`

const LocationData = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: space-between;
    align-items: center;
    @media (min-width: 800px) {
        text-align: left;
        align-items: start;
    }
`
const LocationDataRow = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
    @media (max-width: 799px) {
      align-items: center;
    }
    @media (min-width: 800px) {
    flex-direction: row;
    text-align: left;
    }
`

const Icon = styled.img`
    grid-area: icon;
    height: 60px;
    @media (min-width: 800px) {
        margin-right: 35px;
    }
`

const Name = styled.h2`
    grid-area: name;
    white-space: nowrap;
`

const Role = styled.h3`
    grid-area: role;
    opacity: 60%;
    white-space: nowrap;
    font-size: 1em;
`

const Address = styled.h2`
    grid-area: address;
    white-space: nowrap;
    //::after {
    //    content: ",";
    //}
`

const Zip = styled.h2`
    grid-area: zip;
`

const Phone = styled.div`
    display: flex;
    flex-direction: column;
    justify-items: center;
    
    @media (min-width: 800px) {
        align-items: center;
        flex-direction: row;
        width: 385px;
    }
`

const PhoneNumber = styled.h2`
    text-decoration: none;
    color: inherit;
`
const PhoneNumberLink = styled(Obfuscate)`
    text-decoration: none;
    color: inherit;
`

const Mail = styled.div`
    grid-area: mail;
    display: flex;
    flex-direction: column;
   
    justify-items: center;
    @media (min-width: 800px) {
        flex-direction: row;
        align-items: center;
    }
`

const MailAddress = styled.h2`
    grid-area: mail-address;
`

const MailAddressLink = styled(Obfuscate)`
    text-decoration: none;
    color: inherit;
`

const Codes = styled.div`
    grid-area: codes;
    display: grid;
    grid-template:
        "nip"
        "regon"
        "krs";
    grid-gap: 20px;
    @media (min-width: 800px) {
        grid-template: "nip regon krs";
        grid-gap: 0px;
        font-size: 0.5em;
        margin-left: 95px;
        text-align: left;
    }
`

const NIP = styled.h2`
    grid-area: nip;
    opacity: 60%;
    ::before {
        content: "NIP: ";
    }
`

const Regon = styled.h2`
    grid-area: regon;
    opacity: 60%;
    ::before {
        content: "REGON: ";
    }
`

const KRS = styled.h2`
    grid-area: krs;
    opacity: 60%;
    ::before {
        content: "KRS: ";
    }
`
const LineDivider = styled.div`
    grid-area: lineDivider;
    width: 100%;
    margin-left: inutial;
    justify-self: center;
     height: 2px;
    background-color: #56c2e8;
     @media (min-width: 800px) {
        margin-left: 95px;
        width: calc(100% - 95px);
    }
`

function FirstSection({
    header,
    contactData: { name: main_name, address: main_address, zip: main_zip, phoneNumber: main_phoneNumber, mail: main_mail, nip: main_nip, regon: main_regon, krs: main_krs },
    background: { url },
    contacts,
}) {
    let i = 1;
    return (
        <ContactContainer height="100vh" background={url}>
            <Content>
                <ContactHeader dangerouslySetInnerHTML={{ __html: header }} />
                <Contact>
                    <Location>
                        <Icon src={icons.pin} />
                        <LocationData>
                            <Name>{main_name}</Name>
                            <LocationDataRow>
                                <Address>{main_address}, </Address>
                                <Zip>{main_zip}</Zip>
                            </LocationDataRow>
                        </LocationData>


                    </Location>
                </Contact>
                <Contact>
                    <ContactData>
                        <Phone>
                            <Icon src={icons.phone} />
                            <PhoneNumber>
                                <PhoneNumberLink tel={main_phoneNumber} />
                            </PhoneNumber>
                        </Phone>
                        <Mail>
                            <Icon src={icons.mail} />
                            <MailAddress>
                                <MailAddressLink email={main_mail}/>
                            </MailAddress>
                        </Mail>
                    </ContactData>
                </Contact>
                <LineDivider/>
                <PersonalContacts>
                    <Icon src={i++%2===1?icons.male:icons.female} />
                {
                    contacts.map(({ address, zip, krs, mail, name, nip, phoneNumber, regon, role }, id) => (
                        <PersonalContact key={id}>
                                <PersonalContactData>
                                    <Name>{name}</Name>
                                    <Role>{role}</Role>
                                    <PhoneNumber>
                                        <PhoneNumberLink tel={phoneNumber} />
                                    </PhoneNumber>
                                    <Address>{address}</Address>
                                    <Zip>{zip}</Zip>
                                </PersonalContactData>
                        </PersonalContact>
                        ))
                }
                </PersonalContacts>
                <Codes>
                    <NIP>{main_nip}</NIP>
                    <Regon>{main_regon}</Regon>
                    <KRS>{main_krs}</KRS>
                </Codes>
            </Content>
        </ContactContainer>
    )
}

export default FirstSection
