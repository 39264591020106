import React, { useEffect, useState } from 'react'
import styled from "styled-components";
import Section from '../section';
import { getUrl } from '../../config/server';
import { Button } from '../Button';
import  Transition from '../Slider/Transition';
import  Tears from '../Slider/Tears';

const Container = styled(Section)`
    display: flex;
    background-color: #111;
    background-position: left bottom;
    color: white;
    justify-content: center;
`
const Content = styled.div`
    position: relative;
    display: grid;
    width: 100%;
    grid-template:
      "." 40px
      "header" 50px
      "." 20px
      "subheader" 50px
      "." 10px
      "description" 120px
      "image-div" 500px
      "tears-wrapper" 80px
      "." 20px
      "button" 40px
      "." 40px
      / 100%;
    justify-items: center;
    //background: #111;
    color: white;
    justify-self: center;
    @media (min-width: 800px) {
        grid-template:
            "image-div . ." 140px
            "image-div header ." 70px
            "image-div . ." 130px
            "image-div subheader ." 70px
            "image-div . ." 60px
            "image-div description ." 70px
            "image-div . ." 120px
            "image-div button ." 70px
            "image-div . ." 140px
            / 45vw 42vw 13vw;
            align-self: center;
            justify-items: flex-end;
            position: relative;
    }
`

const Header = styled.h1`
    grid-area: header;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    font-size: 2.5rem;
    color: #56c2e8;
    white-space: nowrap;
    @media (min-width: 800px) {
        font-size: 4rem;
    }
    @media (min-width: 1400px) {
        font-size: 4.9rem;
    }
`;

const Subheader = styled.div`
    grid-area: subheader;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    @media (min-width: 800px) {
        justify-content: space-between;
    }
`;
const Line = styled.div`
    max-width: 260px;
    width: 60px;
    height: 2px;
    background-color: #56c2e8;
    margin-left: 2w;
    margin-right: 1vw;
    @media (min-width: 500px) {
        width: 120px;
        margin-left: 8vw;
    }
    @media (min-width: 800px) {
        width: 100%;
        margin-left: 8vw;
    }
`;

const SubheaderText = styled.h2`
    display: flex;
    justify-content: center;
    align-items: right;
    font-size: 1.75rem;
    white-space: nowrap;
    width: 26vw;
    @media (min-width: 800px) {
        font-size: 2.5rem;
            justify-content: flex-end;
    }
`;

const Description = styled.div`
    grid-area: description;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1em;
    text-align: center;
    @media (min-width: 800px) {
        //justify-content: flex-end;
        align-items: flex-start;
        font-size: 1.25rem;
        text-align: right;
    }
`;
const DescriptionTextContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 2rem;
    @media (min-width: 800px) {
        font-size: 1.25rem;
    }
`;
const DescriptionText = styled.p`
    width: 50vw;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 1rem;
    @media (min-width: 800px) {
        font-size: 1.25rem;
        justify-items: center;
    }
`;
const TearsWrapper = styled.div`
    grid-area: tears-wrapper;
    display: flex;
`;

const ImageDiv = styled.div`
    grid-area: image-div;
    display: flex;
    align-items: center;
    height: 500px;
    width: 100%;
    max-width: 800px;
    ${props => props.background && `background: url(${getUrl(props.background)});`}
    background-position: bottom left;
    background-repeat: no-repeat;
    justify-self: center;
    text-align: center;
    @media (min-width: 800px) {
        justify-self: flex-start;
        text-align: right;
        height: 114%;
        width: 100%;
    }
`;
const ImageTransitionWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: right;
    width: 100vw;
    @media (min-width: 800px) {
    width: 45vw;
        font-size: 2.5rem;
            justify-content: center;
    }
`;

const Image = styled.img`
    height: 400px;
    margin-right: 2vw;
    
    @media (min-width: 800px) {
        height: 700px;
        margin-right: 5vw;
    }
`;

function ThirdSection({
    header,
    slider,
    portfolioButton,
    background:{url},
    lang
}) {
    const [slide, setSlide] = useState(0)

    useEffect(() => {
        const id = setInterval(() => {
            setSlide(slide => (slide < slider.length - 1 ? slide + 1 : 0))
        }, 9000)

        return () => clearInterval(id)
    }, [slider.length, slide])
    return (
        <Container height="800px">
            <Content>
                <Header>
                    {header}
                </Header>
                <Subheader>
                    <Line/>
                    <Transition index={slide}>
                        <SubheaderText>
                            {slider[slide].title}
                        </SubheaderText>
                    </Transition>
                </Subheader>

                <Description>

                        <DescriptionTextContainer>
                            <Transition index={slide} style={{gridArea: "description"}}>
                                <DescriptionText>
                            {slider[slide].description}
                                </DescriptionText>
                            </Transition>
                        </DescriptionTextContainer>
                </Description>
                <ImageDiv background={url}>
                    <Transition index={slide}>
                        <ImageTransitionWrapper>
                            <Image src={getUrl(slider[slide].image.url)} />
                        </ImageTransitionWrapper>
                    </Transition>
                </ImageDiv>
                <TearsWrapper>
                    <Tears
                        data={slider}
                        position={"right"}
                        slide={slide}
                        setSlide={setSlide}
                        paddingTop={"300px"}
                        paddingBottom={"270px"}
                        height={"100%"}
                    />
                </TearsWrapper>
                <Button color="#fff" background="#56C2E8" to={lang==="pl"?"/portfolio":"/en/portfolio"}>
                    {portfolioButton}
                </Button>
            </Content>
        </Container>
    )
}

export default ThirdSection
