import styled from "styled-components"
import { Link } from "gatsby"

export const Button = styled(Link)`
    grid-area: button;
    background-color: ${props => props.background};
    border: none;
    color: ${props => props.color};
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    font-size: 1rem;
    height: 40px;
    box-shadow: 0px 0px 30px -10px rgba(0, 0, 0, 0.75);
    letter-spacing: 1px;
    text-transform: uppercase;
    padding: 0 30px;
    z-index: 2;
    @media (min-width: 800px) {
        padding: 0 60px;
        height: 60px;
    }`
