import React from "react"
import { Container, Header, Question } from "../shared/SectionComponents"

import styled from "styled-components"

const Content = styled.div`
    grid-area: content; height: 100%;
    position: relative;
    display: grid;
    grid-template:
        "header"
        "." 60px
        "question"
        "." 80px
        "options";
    justify-items: center;
    text-align: center;
    @media (min-width: 800px) {
        position: relative;
        grid-template:
            "header"
            "." 150px
            "question"
            "." 72px
            "options"
            / 100%;
        justify-items: flex-start;
    }
`

const Options = styled.div`
    grid-area: options;
    display: grid;
    grid-template:
        "first"
        "." 20px
        "divider"
        "." 20px
        "second";
    @media (min-width: 800px) {
        grid-template:
            "first . divider . second"
            / max-content 50px 1px 50px max-content;
    }
`

const First = styled.h3`
    cursor: pointer;
    grid-area: first;
    font-size: 2rem;
    @media (min-width: 800px) {
        font-size: 2.5rem;
    }
`

const Divider = styled.div`
    grid-area: divider;
    background: black;
    height: 1px;
    width: 100%;

    @media (min-width: 800px) {
        width: 1px;
        height: 100%;
    }
`

const Second = styled.h3`
    cursor: pointer;
    grid-area: second;
    font-size: 2rem;
    @media (min-width: 800px) {
        font-size: 2.5rem;
    }
`

function FirstSection({
    header,
    question,
    numbersOption,
    teamOption,
    background: { url },
}) {
    return (
        <Container height="100vh" background={url}>
            <Content>
                <Header dangerouslySetInnerHTML={{ __html: header }} />

                <Question>{question}</Question>

                <Options>
                    <First
                        onClick={() =>
                            document
                            .getElementById("about-numbers")
                            .scrollIntoView({ behavior: "smooth" })
                        }
                    >{numbersOption}</First>
                    <Divider />
                    <Second
                        onClick={() =>
                            document
                            .getElementById("about-teams")
                            .scrollIntoView({ behavior: "smooth" })
                        }
                    >
                        {teamOption}
                    </Second>
                </Options>
            </Content>
        </Container>
    )
}

export default FirstSection
