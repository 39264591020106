import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import AboutPage from "../components/About/AboutPage"
import { preventWidowsAndOrphans } from "../utils/text";

function About({pageContext}) {
    const {
        strapi: { aboutPage, mainPage },
    } = useStaticQuery(graphql`
        {
            strapi {
                aboutPage {
                    dark
                    firstSection {
                        background {
                            url
                        }
                        question
                        teamOption
                        numbersOption
                        header
                    }
                    sideBackground {
                        url
                    }
                    secondSection {
                        title
                        header
                        description
                        photo {
                            url
                        }
                        description_formatted
                    }
                    thirdSection {
                        header
                        gallery {
                            url
                            width
                            height
                        }
                    }
                }
                mainPage {
                    secondSection {
                        aboutButton
                        header
                        elements {
                            image {
                                url
                            }
                            description
                            number
                        }
                    }
                }
            }
        }
    `)
    aboutPage.secondSection.description = preventWidowsAndOrphans(aboutPage.secondSection.description);

    return <AboutPage context={pageContext} {...aboutPage} mainPage={mainPage} />
}

export default About
