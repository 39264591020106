import React from "react"
import styled from "styled-components"
import { getUrl } from "../../config/server"
import { Container as SectionContainer } from "../shared/SectionComponents"
import ReactMarkdown from "react-markdown";

const Container = styled(SectionContainer)`
    padding-top: 20px;
    @media (min-width: 800px) {
        padding-top: 20px;
        padding-bottom: 60px;
    }
    @media (min-width: 1200px) {
        padding-top: 20px;
        padding-bottom: 60px;
    }
`

const Content = styled.div`
    grid-area: content; height: 100%;
    display: grid;
    grid-template:
        "title"
        "." 72px
        "header"
        "." 72px
        "divider"
        "." 72px
        "description"
        "." 120px
        "photo"
        "." 30px
        / 100%;
    text-align: left;
`

const Title = styled.h1`
    grid-area: title;
    font-size: 2em;
    max-width:50vw;
    @media (min-width: 800px) {
        font-size: 5em;
    }
`

const Header = styled.h2`
    grid-area: header;
    font-size: 1.2em;
    max-width:50vw;
    text-align: left;
    @media (min-width: 800px) {
        font-size: 3.2em;
    }
`

const Divider = styled.div`
    grid-area: divider;
    height: 2px;
    width: 80%;
    background-color: #56c2e8;
    justify-self: center;
    @media (min-width: 800px) {
        width: 280px;
        justify-self: flex-start;
    }
`

const Description = styled.p`
    grid-area: description;
    max-width:50vw;
    opacity: 50%;   
    @media (min-width: 800px) {
        font-size: 1.25em;
    }
`

const Photo = styled.img`
    grid-area: photo;
    max-width: 100%;
    place-self: center center;
`

function SecondSection({ title, header, description, photo, description_formatted }) {
    return (
        <Container id="about-numbers">
            <Content>
                <Title>{title}</Title>
                <Header>{header}</Header>
                <Divider />
                <Description><ReactMarkdown
                    source={description_formatted}
                /></Description>
                <Photo src={getUrl(photo?.url)} alt="team" />
            </Content>
        </Container>
    )
}

export default SecondSection
