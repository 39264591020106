export const icons = {
    pin: require("../assets/icons/ic_location.svg"),
    phone: require("../assets/icons/ic_phone.svg"),
    mail: require("../assets/icons/ic_mail.svg"),
    back: require("../assets/icons/ic_back.svg"),
    male: require("../assets/icons/ic_male.svg"),
    female: require("../assets/icons/ic_female.svg")
}

export const collagePhotos = {
    2: {src:require("../assets/photos/2.jpg"), rows:1, columns:1},
    3: {src:require("../assets/photos/3.jpg"), rows:2, columns:1},
    4: {src:require("../assets/photos/4.jpg"), rows:1, columns:1},
    5: {src:require("../assets/photos/5.jpg"), rows:1, columns:1},
    6: {src:require("../assets/photos/6.jpg"), rows:1, columns:1},
    10: {src:require("../assets/photos/7.jpg"), rows:1, columns:1},
    7: {src:require("../assets/photos/8.jpg"), rows:2, columns:1},
    8: {src:require("../assets/photos/9.jpg"), rows:1, columns:1},
    9: {src:require("../assets/photos/10.jpg"), rows:2, columns:1},
    17: {src:require("../assets/photos/11.jpg"), rows:1, columns:1},
    13: {src:require("../assets/photos/12.jpg"), rows:1, columns:1},
    14: {src:require("../assets/photos/13.jpg"), rows:2, columns:1},
    15: {src:require("../assets/photos/14.jpg"), rows:1, columns:1},
    16: {src:require("../assets/photos/15.jpg"), rows:2, columns:2},
    12: {src:require("../assets/photos/16.jpg"), rows:1, columns:1},
    11: {src:require("../assets/photos/17.jpg"), rows:1, columns:1},
    18: {src:require("../assets/photos/18.jpg"), rows:1, columns:1},

}

export const logo = {
    icon: require("../assets/images/img_logo_icon.svg"),
    iconLight: require("../assets/images/img_logo_icon_light.svg"),
    full:  require("../assets/images/img_logo_complete.svg"),
    fullLight:  require("../assets/images/img_logo_complete_light.svg")
}
