import React from "react"

import Layout from "../layout"
import FirstSection from "./FirstSection";
import SecondSection from "./SecondSection";
import ThirdSection from "./ThirdSection";
import FourthSection from "./FourthSection";

function MainPage({
    context,
    dark,
    firstSection,
    secondSection,
    thirdSection,
    fourthSection
}){
    const lang = context.langKey;
    return (
        <Layout context={context} dark={dark?1:0} title="Home" withBack={false}>
            <FirstSection {...firstSection} lang={lang}/>
            <SecondSection {...secondSection} lang={lang}/>
            <ThirdSection {...thirdSection} lang={lang}/>
            <FourthSection {...fourthSection} />
        </Layout>
    )

}

export default MainPage
