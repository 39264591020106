import React, { useState, useEffect } from "react"
import styled from "styled-components"
//import Transition from "./Transition"
import Tears from "./Tears"
import { getUrl } from "../../config/server";

const Container = styled.div`
    grid-area: slider;
    height: 100%;
    width: 100%;
    display: grid;
    position: relative;
`
const ImagesContainer = styled.div`
    height: 100%;
    width: 100%;
    position: relative;
`
const Image = styled.img`
    width: 100%;
    transition: 1s;
     ${props => props.visible && props.visible===true ? `opacity:1; position:relative;`:`position: absolute; opacity:0; left: 0;`}
    @media (min-width: 800px) {
        height: 100%;
        object-fit: cover;
    }
`

function Slider({ data, isEven, paddingTopTears,
                    paddingBottomTears }) {
    const [slide, setSlide] = useState(0)

    useEffect(() => {
        const id = setInterval(() => {
            setSlide(slide => (slide < data.length - 1 ? slide + 1 : 0))
        }, 10000)

        return () => clearInterval(id)
    }, [data.length, slide])

    return (
        <Container>
            <ImagesContainer>
                {data.map((item, index)=> {
                    const visible = slide===index;
                    //return render({...item, visible})
                    return <Image src={getUrl(item.url)} alt="" visible={visible} key={index}/>
                }
                )
                }
            {/*{render(data[slide] || {})}*/}
            </ImagesContainer>
            <Tears
                data={data}
                position={isEven?"right":"left"}
                slide={slide}
                setSlide={setSlide}
                height={"100%"}
                paddingTop={paddingTopTears??"200px"}
                paddingBottom={paddingBottomTears??"200px"}
            />
            {/*
            <Tears isEven={isEven}>
                {data.map((_, it) => (
                    <Tear
                        key={it}
                        onClick={() => setSlide(it)}
                        selected={slide === it}
                    />
                ))}
            </Tears>*/}

        </Container>
    )
}

export default Slider
