import React from "react"

import Layout from "../layout"
import FirstSection from "./FirstSection"
import SecondSection from "./SecondSection"
import ThirdSection from "./ThirdSection"
import FourthSection from "../Main/FourthSection"

function OfferPage({
    context,
    dark,
    firstSection,
    secondSection,
    thirdSection,
    fourthSection
}) {
    const lang = context.langKey;
    return (
        <Layout context={context} dark={dark?1:0} title="Offer">
            {typeof window !== "undefined" && !window.location.href.includes(":8000") && !window.location.href.includes("new") && window.gtag('event', 'conversion', {'send_to': 'AW-981106108/57-oCODihPEBELz76dMD'})}
            <FirstSection {...firstSection} />
            <SecondSection {...secondSection} lang={lang}/>
            <ThirdSection {...thirdSection} lang={lang}/>
            <FourthSection {...fourthSection} />
        </Layout>
    )
}
export default OfferPage
