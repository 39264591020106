import React, { useMemo } from "react"
import styled, { keyframes } from "styled-components"
import { getUrl } from "../../config/server"

const Animation = (from, to) => keyframes`
    from {
        transform: translateX(${from}px);
    }

    to {
        transform: translateX(max(${to}px, calc(100vw + ${-from}px)));
    }
`;

const Container = styled.div`
    grid-area: gallery;
    display: grid;
    position: relative;
    grid-auto-flow: row;
    align-items: center;
    justify-items: center;
    transform: translateX(-50vw);
`

const Company = styled.img`
    grid-area: 1;
    height: 100px;
    position: absolute;
    animation: ${props => Animation(props.from, props.to)} ${props => props.amount * props.slower}s infinite linear;
    animation-delay: -${props => props.shift * props.slower}s;
`

function Gallery({ companies }) {
    const maxWidth = useMemo(() => Math.max(...companies.map(({width, height}) => 100 * height / width)), [companies])
    const slideshowWidth = useMemo(() => companies.reduce((p, n) => p + (100 * n.height / n.width) + 50, 0), [companies])

    return (
        <Container>
            {companies.map(({ url, width }, it) => (
                <Company key={it} src={getUrl(url)} shift={it} amount={companies.length} from={-maxWidth} to={slideshowWidth + maxWidth} slower={5} />
            ))}
        </Container>
    )
}

export default Gallery
